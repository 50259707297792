import { MenuOption } from 'shamrock-clover-ui/dist/clover/components/Menus/SingleSelectMenu';
import {
  SET_AGING_BREADCRUMB_HISTORY,
  SET_AGING_DATA_LOADING,
  SET_AGING_DATA_LOADING_FAILED,
  SET_AGING_INVOICE,
  SET_AGING_PAGE_INDEX,
  SET_AGING_REPORT_DATA,
  SET_AS_OF_DATE,
  SET_CURRENT_PAGE,
  SET_TOTAL_ITEMS,
  SET_FILTER_BY,
  SET_FILTER_BY_CREDIT_RATING,
  SET_FILTER_BY_SEARCH,
  SET_OLDER_THAN_DAYS,
  SET_PRINT_IN_PROGRESS,
  SET_ROWS_PER_PAGE,
  SET_CLIENT,
  SET_NONFACTORED_AGING_ITEMS,
  SET_NONFACTORED_LOADING,
  SET_NONFACTORED_LOADING_FAILED,
  SET_NONFACTORED_SEARCH_FILTERS,
  SET_NONFACTORED_CURRENT_PAGE,
  SET_NONFACTORED_TOTAL_ITEMS,
  SET_NONFACTORED_PAGE_COUNT,
  SET_NONFACTORED_PAGE_SIZE,
  SET_NONFACTORED_SUMMARY,
  CHECK_ALL_NONFACTORED_AGING_ITEMS,
  UNCHECK_ALL_NONFACTORED_AGING_ITEMS,
  SET_NONFACTORED_AGING_ITEM_CHECKED,
  RESET_NONFACTORED_AGING,
  SET_NONFACTORED_AS_OF_DATE,
  SET_NONFACTORED_OLDER_THAN_DAYS,
  SET_NONFACTORED_FILTER_BY,
  SET_NONFACTORED_FILTER_TEXT,
  SET_NONFACTORED_QUERY_TYPE,
  SET_NONFACTORED_AGING_ITEMS_DEBTOR_INFORMATION,
  SET_NONFACTORED_UNIQUE_MC_NUMBERS,
  SET_INVOICE_SUMMARY_DATA,
  SET_INVOICE_SUMMARY_LOADING_FAILED,
  SET_INVOICE_SUMMARY_LOADING,
  SET_NONFACTORED_PRINTABLE_DATA,
} from '../../constants/agingConstants';
import moment from 'moment';
import { AgingReportLoad } from '../../interfaces/aging-report-load';
import { AgingReportLoadFilter } from '../../interfaces/aging-report-load-filter';

interface ReportData {
  avgDaysToPay: number;
  creditRating: string;
  customerLastUpdated: string;
  customerName: string;
  invoiceAge: number;
  invoiceAmount: number;
  invoiceKey: number;
  invoiceNumber: string;
  loadNumber: string;
  purchaseDate: string;
}

interface SummaryData {
  accountsReceivableBalance: number;
  factoredAccountsReceivableBalance: number;
  nonFactoredAccountsReceivableBalance: number;
  invoiceAmountGreaterThan45Days: number;
  invoiceAmountGreaterThan60Days: number;
  invoiceCount: number;
  factoredInvoiceCount: number;
  nonFactoredInvoiceCount: number;
  invoiceCountGreaterThan45Days: number;
  invoiceCountGreaterThan60Days: number;
}

interface FactoredAgingReportState {
  agingReportData: ReportData[];
  agingReportLoading: boolean;
  agingReportLoadingFailed: boolean;
  olderThanDays: number;
  asOfDate: moment.Moment;
  filterBy: MenuOption;
  filterBySearch: string;
  filterByCreditRating: string[];
  selectedInvoice: object;
  history: string[];
  agingPageIndex: number;
  rowsPerPage: number;
  currentPage: number;
  totalItems: number;
  printInProgress: boolean;
  client?: object;
}
interface NonFactoredAgingReportState {
  nonFactoredAgingReportData: AgingReportLoad[];
  nonFactoredAgingReportLoading: boolean;
  nonFactoredAgingReportLoadingFailed: boolean;
  filterBy: MenuOption;
  filters: AgingReportLoadFilter;
  pageSize: number;
  currentPage: number;
  totalItems: number;
  pageCount: number;
  summary: number;
  uniqueMcNumbers: string[];
  docReadyToPrint: boolean;
  printableData: AgingReportLoad[];
}

interface InvoiceSummaryState {
  summaryData: SummaryData;
  summaryLoading: boolean;
  summaryLoadingFailed: boolean;
}

const invoiceSummaryInitialState: InvoiceSummaryState = {
  summaryData: {
    accountsReceivableBalance: 0,
    factoredAccountsReceivableBalance: 0,
    nonFactoredAccountsReceivableBalance: 0,
    invoiceAmountGreaterThan45Days: 0,
    invoiceAmountGreaterThan60Days: 0,
    invoiceCount: 0,
    factoredInvoiceCount: 0,
    nonFactoredInvoiceCount: 0,
    invoiceCountGreaterThan45Days: 0,
    invoiceCountGreaterThan60Days: 0,
  },
  summaryLoading: true,
  summaryLoadingFailed: false,
};

const nonFactoredInitialState: NonFactoredAgingReportState = {
  nonFactoredAgingReportData: [],
  nonFactoredAgingReportLoading: true,
  nonFactoredAgingReportLoadingFailed: false,
  filterBy: {
    key: '1',
    id: 'customerName',
    optionName: 'Customer Name',
  },
  filters: {
    olderThanDays: 0,
    asOfDate: moment(),
    queryType: 'olderthan',
    filterType: 'customerName',
    filterText: '',
    sorting: 'age desc',
    pageSize: 10,
    pageNumber: 1,
  },
  pageSize: 10,
  currentPage: 1,
  totalItems: 0,
  pageCount: 0,
  summary: 0,
  uniqueMcNumbers: [],
  docReadyToPrint: false,
  printableData: [],
};

const factoredAgingReportInitialState: FactoredAgingReportState = {
  agingReportLoading: true,
  agingReportLoadingFailed: false,
  agingReportData: [],
  olderThanDays: 0,
  asOfDate: moment(),
  filterBy: {
    key: '1',
    id: 'customerName',
    optionName: 'Customer Name',
  },
  filterBySearch: '',
  filterByCreditRating: ['A', 'B', 'C', 'D', 'N', 'F', 'I'],
  selectedInvoice: {},
  history: ['Aging Report'],
  agingPageIndex: 0,
  rowsPerPage: 10,
  currentPage: 1,
  totalItems: 0,
  printInProgress: false,
  client: undefined,
};

interface AgingReportState {
  factoredAgingReport: FactoredAgingReportState;
  nonFactoredAgingReport: NonFactoredAgingReportState;
  summary: InvoiceSummaryState;
}

export const initialState: AgingReportState = {
  factoredAgingReport: factoredAgingReportInitialState,
  nonFactoredAgingReport: nonFactoredInitialState,
  summary: invoiceSummaryInitialState,
};

export function agingReport(state = initialState, action) {
  switch (action.type) {
    // Factored Aging Report
    case SET_AGING_REPORT_DATA:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          agingReportData: action.agingReportData,
        },
      };
    case SET_AGING_DATA_LOADING:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          agingReportLoading: action.agingReportLoading,
        },
      };
    case SET_AGING_DATA_LOADING_FAILED:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          agingReportLoadingFailed: action.agingReportLoadingFailed,
        },
      };
    case SET_OLDER_THAN_DAYS:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          olderThanDays: action.olderThanDays,
        },
      };
    case SET_FILTER_BY:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          filterBy: action.filterBy,
        },
      };
    case SET_FILTER_BY_SEARCH:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          filterBySearch: action.filterBySearch ?? '',
        },
      };
    case SET_AS_OF_DATE:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          asOfDate: action.asOfDate,
        },
      };
    case SET_FILTER_BY_CREDIT_RATING:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          filterByCreditRating: action.filterByCreditRating,
        },
      };
    case SET_AGING_PAGE_INDEX:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          agingPageIndex: action.agingPageIndex,
        },
      };
    case SET_AGING_BREADCRUMB_HISTORY:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          history: action.history,
        },
      };
    case SET_PRINT_IN_PROGRESS:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          printInProgress: action.printInProgress,
        },
      };
    case SET_AGING_INVOICE:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          history: action.payload.history,
          agingPageIndex: 1,
          invoiceLoading: action.payload.loading,
          selectedInvoice: action.payload.selectedInvoice,
        },
      };
    case SET_ROWS_PER_PAGE:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          rowsPerPage: action.rowsPerPage,
        },
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          currentPage: action.currentPage,
        },
      };
    case SET_TOTAL_ITEMS:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          totalItems: action.totalItems,
        },
      };
    case SET_CLIENT:
      return {
        ...state,
        factoredAgingReport: {
          ...state.factoredAgingReport,
          client: action.client,
        },
      };
    // NonFactored Aging Report
    case SET_NONFACTORED_AGING_ITEMS:
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          nonFactoredAgingReportData: action.payload,
        },
      };
    case SET_NONFACTORED_LOADING:
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          nonFactoredAgingReportLoading: action.payload,
        },
      };
    case SET_NONFACTORED_LOADING_FAILED:
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          nonFactoredAgingReportLoadingFailed: action.payload,
        },
      };
    case SET_NONFACTORED_OLDER_THAN_DAYS:
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          filters: { ...state.nonFactoredAgingReport.filters, olderThanDays: action.payload },
        },
      };
    case SET_NONFACTORED_AS_OF_DATE:
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          filters: { ...state.nonFactoredAgingReport.filters, asOfDate: action.payload },
        },
      };
    case SET_NONFACTORED_SEARCH_FILTERS:
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          filters: action.payload,
        },
      };
    case SET_NONFACTORED_PAGE_SIZE:
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          pageSize: action.payload,
        },
      };
    case SET_NONFACTORED_CURRENT_PAGE:
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          currentPage: action.payload,
        },
      };
    case SET_NONFACTORED_TOTAL_ITEMS:
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          totalItems: action.payload,
        },
      };
    case SET_NONFACTORED_PAGE_COUNT:
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          pageCount: action.payload,
        },
      };
    case SET_NONFACTORED_SUMMARY:
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          summary: action.payload,
        },
      };
    case SET_NONFACTORED_AGING_ITEM_CHECKED: {
      const updatedItem = action.payload;
      const updatedNonFactoredAgingReportData = state.nonFactoredAgingReport.nonFactoredAgingReportData.map(item => {
        if (item.loadId === updatedItem.loadId) {
          return {
            ...item,
            isChecked: updatedItem.isChecked,
          };
        }
        return item;
      });
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          nonFactoredAgingReportData: updatedNonFactoredAgingReportData,
        },
      };
    }
    case CHECK_ALL_NONFACTORED_AGING_ITEMS: {
      const allCheckedItems = state.nonFactoredAgingReport.nonFactoredAgingReportData.map(item => ({
        ...item,
        isChecked: true,
      }));
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          nonFactoredAgingReportData: allCheckedItems,
        },
      };
    }
    case UNCHECK_ALL_NONFACTORED_AGING_ITEMS: {
      const allUncheckedItems = state.nonFactoredAgingReport.nonFactoredAgingReportData.map(item => ({
        ...item,
        isChecked: false,
      }));
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          nonFactoredAgingReportData: allUncheckedItems,
        },
      };
    }
    case SET_NONFACTORED_FILTER_BY: {
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          filterBy: action.payload,
          filters: { ...state.nonFactoredAgingReport.filters, filterType: action.payload.id },
        },
      };
    }
    case SET_NONFACTORED_FILTER_TEXT: {
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          filters: {
            ...state.nonFactoredAgingReport.filters,
            filterText: action.payload,
          },
        },
      };
    }
    case SET_NONFACTORED_QUERY_TYPE: {
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          filters: {
            ...state.nonFactoredAgingReport.filters,
            queryType: action.payload,
          },
        },
      };
    }
    case RESET_NONFACTORED_AGING: {
      return {
        ...state,
        nonFactoredAgingReport: nonFactoredInitialState,
      };
    }
    case SET_NONFACTORED_UNIQUE_MC_NUMBERS: {
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          uniqueMcNumbers: action.payload,
        },
      };
    }
    case SET_NONFACTORED_AGING_ITEMS_DEBTOR_INFORMATION: {
      const debtors = action.payload;
      const currentAgingItems = state.nonFactoredAgingReport.nonFactoredAgingReportData;
      const updatedAgingItems = currentAgingItems.map(item => {
        const matchingDebtor = debtors.find(debtor => debtor?.mc_number === item?.mcNumber);
        if (matchingDebtor) {
          item = {
            ...item,
            rating: matchingDebtor?.rating,
            averageDaysToPayPast60: matchingDebtor?.averageDaysToPayPast60,
            lastCreditScoreChange: matchingDebtor?.lastCreditScoreChange,
          };
        } else {
          item = {
            ...item,
            rating: 'N',
            averageDaysToPayPast60: 'NA',
            lastCreditScoreChange: undefined,
          };
        }

        return item;
      });

      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          nonFactoredAgingReportData: updatedAgingItems,
        },
      };
    }
    case SET_NONFACTORED_PRINTABLE_DATA: {
      const readyToPrint = action.payload?.length > 0;
      return {
        ...state,
        nonFactoredAgingReport: {
          ...state.nonFactoredAgingReport,
          docReadyToPrint: readyToPrint,
          printableData: action.payload,
        },
      };
    }
    // Summary
    case SET_INVOICE_SUMMARY_DATA: {
      return {
        ...state,
        summary: {
          ...state.summary,
          summaryData: action.payload,
        },
      };
    }
    case SET_INVOICE_SUMMARY_LOADING: {
      return {
        ...state,
        summary: {
          ...state.summary,
          summaryLoading: action.payload,
        },
      };
    }
    case SET_INVOICE_SUMMARY_LOADING_FAILED: {
      return {
        ...state,
        summary: {
          ...state.summary,
          summaryLoadingFailed: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
