import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import customHistory from '../../federation/historyRouter/history';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} location={location} params={params} navigate={navigate} history={customHistory} />;
  }

  return ComponentWithRouterProp;
}

export { withRouter };
