import Axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import store from '../redux/configureStore';
import { getMaintenanceAlert } from '../redux/actions/appActions';
import { redirectToLogout } from '../utils/authUtils';
import LogService from './log.service';
import { Log } from '../interfaces/log';
import gk2 from '@shamrock-core/gk2';
import { UserStatus } from 'interfaces/user';

const Config = require('Config');

export default class Service {
  axios: AxiosInstance;
  private disableLogging: boolean;

  constructor(serviceUrl: string, disableLogging: boolean = false) {
    this.createAxios(serviceUrl);
    this.disableLogging = disableLogging;
  }

  createAxios(url: string) {
    this.axios = Axios.create({
      baseURL: url,
    });

    this.axios.interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => this.requestInterceptor(config),
      (error: AxiosError): Promise<AxiosError> => Promise.reject(error),
    );

    this.axios.interceptors.response.use(
      response => response,
      error => this.responseErrorInterceptor(error),
    );
  }

  getAxiosInstance = (url: string) => {
    const axiosInstance = Axios.create({ baseURL: url });
    axiosInstance.interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => this.requestInterceptor(config),
      (error: AxiosError): Promise<AxiosError> => Promise.reject(error),
    );

    axiosInstance.interceptors.response.use(
      response => response,
      error => this.responseErrorInterceptor(error),
    );

    return axiosInstance;
  };

  private requestInterceptor = (config: AxiosRequestConfig) => {
    let token = LocalAuth.getTokenValue();
    const isTrial = (LocalAuth.getUser()?.userStatus as UserStatus)?.isTrial;
    const deviceId = localStorage.getItem('device_id');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config = gk2.webInterceptor(config, isTrial, deviceId) as AxiosRequestConfig;
    return config;
  };

  private responseErrorInterceptor = async error => {
    if (!this.disableLogging) {
      const log: Log = { user: LocalAuth.getEmail(), error: JSON.stringify(error), additionalDetails: '' };
      new LogService().log(log);
    }
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      redirectToLogout(Config.loadManagementWebUrl);
    }
    if (error?.response?.status === 500 || (!error.status && error.message === 'Network Error')) {
      await store.dispatch(getMaintenanceAlert() as any);
    }

    return Promise.reject(error);
  };
}
