import React from 'react';
import { TFunction } from 'i18next';
import { Step } from 'react-joyride';

export const invoicingJoyrideSteps = (t: TFunction) => {
  const steps: Step[] = [
    // NOTE: Step 1 is a dummy to make the count right with the initial modal
    {
      target: '[data-joyrideid="joyride_invoicing_step_2"]',
      content: <div>{t('joyride_invoicing_step_2')}</div>,
      disableBeacon: true,
      showSkipButton: true,
    },
    {
      target: '[data-joyrideid="joyride_invoicing_step_2"]',
      title: t('joyride_invoicing_step_2_title'),
      content: <div>{t('joyride_invoicing_step_2')}</div>,
      placement: 'right',
      disableBeacon: true,
      showSkipButton: false,
      hideBackButton: true,
    },
    {
      target: '[data-joyrideid="joyride_invoicing_step_3"]',
      content: <div>{t('joyride_invoicing_step_3')}</div>,
      title: t('joyride_invoicing_step_3_title'),
      placement: 'right',
      disableBeacon: true,
      showSkipButton: false,
      hideBackButton: true,
    },
    {
      target: '[data-joyrideid="joyride_invoicing_step_4"]',
      content: <div>{t('joyride_invoicing_step_4')}</div>,
      title: t('joyride_invoicing_step_4_title'),
      placement: 'left',
      disableBeacon: true,
      showSkipButton: false,
      showProgress: false,
      hideBackButton: true,
    },
  ];
  return steps;
};
