import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceAlert } from 'interfaces/maintenance-alert';
import css from './styles/maintenance-alert-dialog.scss';

interface Props {
  maintenanceAlert: MaintenanceAlert;
}

function MaintenanceAlertDialog({ maintenanceAlert }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className={css.blockContainer} />
      <div className={css.mainContainer}>
        <div className={css.alertContainer}>
          <div className={css.alertDescription}>
            <div className={css.alertTitle}>{t(maintenanceAlert.title)}</div>
            <div className={css.alertSubTitle}>{t(maintenanceAlert.subtitle)}</div>
            <div className={css.alertMessage}>{t(maintenanceAlert.message)}</div>
          </div>
          <img className={css.alertImage} src={maintenanceAlert.image} />
        </div>
      </div>
    </>
  );
}

export default MaintenanceAlertDialog;
