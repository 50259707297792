export function sortString(a, b) {
  if (a === b) {
    return 0;
  } else if (!a) {
    return -1;
  } else if (!b) {
    return 1;
  } else {
    return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
  }
}

export function sortNumber(a, b, reverseUndefined?: boolean) {
  if (a === b) {
    return 0;
  } else if (!a) {
    return reverseUndefined ? 1 : -1;
  } else if (!b) {
    return reverseUndefined ? -1 : 1;
  } else {
    return a > b ? 1 : -1;
  }
}
