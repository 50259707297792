import Service from './service';
import { AxiosResponse } from 'axios';
import { Integration } from '../interfaces/integration';

const Config = require('Config');

export default class FuelService extends Service {
  constructor() {
    super(Config.fuelServiceUrl);
  }

  async getStates(): Promise<any> {
    const response: AxiosResponse = await this.axios.get(`states`);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }
}
