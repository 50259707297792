import * as StatementConstants from '../../constants/statementConstants';

const initialState = {
  displayedStatements: [],
  allStatements: [],
  checkedStatements: [],
  statementListMetaData: {},
  selectedStatement: undefined,
  isEdit: false,
  allChecked: false,
  unsavedChanges: false,
  sortColumn: undefined,
  isAscending: false,
  summary: 0,
};

export default function statementReducer(state = initialState, action) {
  let newState = state;
  switch (action.type) {
    case StatementConstants.SET_STATEMENTS:
      const paginationObject = action.statements;
      const statements = paginationObject.results;
      delete paginationObject.results;
      return {
        ...newState,
        displayedStatements: statements || [],
        allStatements: statements || [],
        statementListMetaData: paginationObject,
        summary: paginationObject.summary,
      };
    case StatementConstants.SET_SORT_COLUMN:
      const newSortColumn = action.sortColumn;
      return {
        ...newState,
        sortColumn: newSortColumn,
      };
    case StatementConstants.SET_SORT_ORDER:
      const newIsAscending = action.isAscending;
      return {
        ...newState,
        isAscending: newIsAscending,
      };
    default:
      return newState;
  }
}
