const NAMESPACE = 'aging/';

// keep olderThan/asOf in same variable or separate? As long as we know they will always stay the same
export const SET_AGING_REPORT_DATA = `${NAMESPACE}SET_AGING_REPORT_DATA`;
export const SET_AGING_DATA_LOADING = `${NAMESPACE}SET_AGING_DATA_LOADING`;
export const SET_AGING_DATA_LOADING_FAILED = `${NAMESPACE}SET_AGING_DATA_LOADING_FAILED`;
export const SET_AGING_INVOICE = `${NAMESPACE}SET_AGING_INVOICE`;
export const SET_AGING_PAGE_INDEX = `${NAMESPACE}SET_AGING_PAGE_INDEX`;
export const SET_AGING_BREADCRUMB_HISTORY = `${NAMESPACE}SET_AGING_BREADCRUMB_HISTORY`;
export const SET_FILTER_BY = `${NAMESPACE}SET_FILTER_BY`;
export const SET_FILTER_BY_SEARCH = `${NAMESPACE}SET_FILTER_BY_SEARCH`;
export const SET_OLDER_THAN_DAYS = `${NAMESPACE}SET_OLDER_THAN_DAYS`;
export const SET_AS_OF_DATE = `${NAMESPACE}SET_AS_OF_DATE`;
// string to keep 'older than' vs 'as of'? Or boolean denoting one or the other named accordingly?
export const AGING_REPORT_TYPE = `${NAMESPACE}AGING_REPORT_TYPE`;
export const SET_PRINT_IN_PROGRESS = `${NAMESPACE}SET_PRINT_IN_PROGRESS`;
export const SET_FILTER_BY_CREDIT_RATING = `${NAMESPACE}SET_FILTER_BY_CREDIT_RATING`;
export const SET_ROWS_PER_PAGE = `${NAMESPACE}SET_ROWS_PER_PAGE`;
export const SET_CURRENT_PAGE = `${NAMESPACE}SET_CURRENT_PAGE`;
export const SET_TOTAL_ITEMS = `${NAMESPACE}SET_TOTAL_ITEMS`;
export const SET_CLIENT = `${NAMESPACE}SET_CLIENT`;
// Non factored
export const SET_NONFACTORED_LOADING = `${NAMESPACE}SET_NONFACTORED_LOADING`;
export const SET_NONFACTORED_AGING_ITEMS = `${NAMESPACE}SET_NONFACTORED_AGING_ITEMS`;
export const SET_NONFACTORED_SEARCH_FILTERS = `${NAMESPACE}SET_NONFACTORED_SEARCH_FILTERS`;
export const SET_NONFACTORED_LOADING_FAILED = `${NAMESPACE}SET_NONFACTORED_LOADING_FAILED`;
export const SET_NONFACTORED_CURRENT_PAGE = `${NAMESPACE}SET_NONFACTORED_CURRENT_PAGE`;
export const SET_NONFACTORED_PAGE_SIZE = `${NAMESPACE}SET_NONFACTORED_PAGE_SIZE`;
export const SET_NONFACTORED_PAGE_COUNT = `${NAMESPACE}SET_NONFACTORED_PAGE_COUNT`;
export const SET_NONFACTORED_TOTAL_ITEMS = `${NAMESPACE}SET_NONFACTORED_TOTAL_ITEMS`;
export const SET_NONFACTORED_SUMMARY = `${NAMESPACE}SET_NONFACTORED_SUMMARY`;
export const CHECK_ALL_NONFACTORED_AGING_ITEMS = `${NAMESPACE}CHECK_ALL_NONFACTORED_AGING_ITEMS`;
export const UNCHECK_ALL_NONFACTORED_AGING_ITEMS = `${NAMESPACE}UNCHECK_ALL_NONFACTORED_AGING_ITEMS`;
export const SET_NONFACTORED_AGING_ITEM_CHECKED = `${NAMESPACE}SET_NONFACTORED_AGING_ITEM_CHECKED`;
export const RESET_NONFACTORED_AGING = `${NAMESPACE}RESET_NONFACTORED_AGING`;
export const SET_NONFACTORED_AS_OF_DATE = `${NAMESPACE}SET_NONFACTORED_AS_OF_DATE`;
export const SET_NONFACTORED_OLDER_THAN_DAYS = `${NAMESPACE}SET_NONFACTORED_OLDER_THAN_DAYS`;
export const SET_NONFACTORED_FILTER_BY = `${NAMESPACE}SET_NONFACTORED_FILTER_BY`;
export const SET_NONFACTORED_FILTER_TEXT = `${NAMESPACE}SET_NONFACTORED_FILTER_TEXT`;
export const SET_NONFACTORED_QUERY_TYPE = `${NAMESPACE}SET_NONFACTORED_QUERY_TYPE`;
export const SET_NONFACTORED_AGING_ITEMS_DEBTOR_INFORMATION = `${NAMESPACE}SET_NONFACTORED_AGING_ITEMS_DEBTOR_INFORMATION`;
export const SET_NONFACTORED_UNIQUE_MC_NUMBERS = `${NAMESPACE}SET_NONFACTORED_UNIQUE_MC_NUMBERS`;
export const SET_NONFACTORED_PRINTABLE_DATA = `${NAMESPACE}SET_NONFACTORED_PRINTABLE_DATA`;
// Summary
export const SET_INVOICE_SUMMARY_DATA = `${NAMESPACE}SET_INVOICE_SUMMARY_DATA`;
export const SET_INVOICE_SUMMARY_LOADING = `${NAMESPACE}SET_INVOICE_SUMMARY_LOADING`;
export const SET_INVOICE_SUMMARY_LOADING_FAILED = `${NAMESPACE}SET_INVOICE_SUMMARY_LOADING_FAILED`;
