import { Provider as ReduxProvider } from 'react-redux';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import ErrorBoundary from '../../components/core/error-boundary';
import { CustomRouter } from '../historyRouter/CustomRouter';
import customHistory from '../historyRouter/history';
import React from 'react';
import Login from '../../components/login/login';
import * as process from 'process';
import * as firebaseUtils from '../../components/core/firebase-analytics';
import Analytics from '../../components/core/analytics';
import Router from './federated-router';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import store from '../../redux/configureStore';
const Config = require('Config');
import css from './federated-app.scss';
import createGenerateClassName from '@material-ui/styles/createGenerateClassName';
import StylesProvider from '@material-ui/styles/StylesProvider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { I18nextProvider } from 'react-i18next';
import { addLoadMangagementLanguageResources } from '../../i18n';
import { setMomentLocale } from '../../utils/timeUtils';
import { i18n } from 'i18next';
window['firebaseModuleName'] = 'Load Management';
window['process'] = process;
require('path');

Analytics.initialize();

const theme = createTheme({
  palette: {
    primary: { main: '#0091ea' },

    secondary: {
      main: '#0091ea',
    },
  },
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'tms',
  disableGlobal: true,
});

interface Props {
  i18n: i18n;
  clientNumber: unknown;
  features: unknown;
  factoringClientSelector?: JSX.Element;
  header?: JSX.Element;
}

const TMSApp = ({ clientNumber, i18n: i18nFromShell, features, factoringClientSelector, header }: Props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isLanguageFilesLoaded, setIsLanguageFilesLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!isLanguageFilesLoaded) {
      addLoadMangagementLanguageResources(i18nFromShell);
    }

    const init = async () => {
      await firebaseUtils.init(Config.firebaseConfig).catch(error => {
        console.error(error.message);
      });
      setIsLanguageFilesLoaded(true);
      setIsLoaded(true);
      setMomentLocale(i18nFromShell.language);
    };

    init();
  }, [i18nFromShell, isLanguageFilesLoaded]);

  return (
    <ReduxProvider store={store}>
      <ErrorBoundary>
        <I18nextProvider i18n={i18nFromShell}>
          <CustomRouter history={customHistory}>
            {/* @ts-ignore Issue with using withRouter */}
            <Login features={features} />
            {isLoaded ? (
              <MuiThemeProvider theme={theme}>
                <StylesProvider generateClassName={generateClassName}>
                  <div className={css.page}>
                    <Router
                      features={features}
                      clientNumber={clientNumber}
                      factoringClientSelector={factoringClientSelector}
                      header={header}
                    />
                  </div>
                </StylesProvider>
              </MuiThemeProvider>
            ) : (
              <CircularProgress className={css.loadingSpinner} variant={'indeterminate'} size={60} />
            )}
          </CustomRouter>
        </I18nextProvider>
      </ErrorBoundary>
    </ReduxProvider>
  );
};

export default TMSApp;
