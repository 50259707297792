import MockDispatchService from './mock-dispatch.service';

class MockDispatchServiceSingleton {
  private static instance: MockDispatchService;

  static getInstance(numberOfDriversPerGroup: number = 20, numberOfTripsPerDay: number = 2): MockDispatchService {
    if (!MockDispatchServiceSingleton.instance) {
      MockDispatchServiceSingleton.instance = new MockDispatchService(numberOfDriversPerGroup, numberOfTripsPerDay);
    }
    return MockDispatchServiceSingleton.instance;
  }
}

export default MockDispatchServiceSingleton;
