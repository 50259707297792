import { AdvancedLoadItem } from 'interfaces/advanced-load-item';
import * as AdvancedLoadsConstants from '../../constants/advancedLoadsConstants';
import { LoadStatus } from '../../enums/load-status';
import { AdvancedLoadsSearchFilter } from '../../interfaces/advanced-loads-search-filter';
import { PaginationData } from '../../interfaces/pagination-data';
import { AdvancedLoadsSummary } from '../../interfaces/advanced-loads-summary';
import { BillingItem } from '../../interfaces/billing-item';
import BillingValidation from '../../interfaces/billing-validation';
import { AdvancedLoadDetail } from '../../interfaces/advanced-load-detail';
import { BusinessPartnerContact } from '../../components/partners/interfaces/BusinessPartnerContact';
import { ValidationError } from '../../interfaces/validationError';

export interface InvoiceRequestToken {
  loadId: number;
  invoiceRequestToken: number;
  processing: boolean;
}

export interface FinishedInvoiceStatus {
  loadId: number;
  completed: string[];
  failed: string[];
}

export interface SnackBarConfig {
  open: boolean;
  message: string;
  type: 'error' | 'info' | 'success' | undefined;
  time: number;
  action: () => void;
  width: string;
}

export interface LoadCopyValidations {
  stopDateCopyError: { hasError: boolean };
  scheduledFinalPlainDatetimeCopyError: { hasError: boolean };
  scheduledPlainDateTimeCopyError: { hasError: boolean };
}

export interface LoadValidations {
  loadInfo?: ValidationError[];
  stops?: ValidationError[][];
  copy?: LoadCopyValidations[];
}

export const initialState = {
  load: {} as AdvancedLoadItem,
  loadDetails: {} as AdvancedLoadDetail,
  invoiceRequests: [] as InvoiceRequestToken[],
  displayedLoads: [] as AdvancedLoadItem[],
  pagination: {} as PaginationData,
  summary: {} as AdvancedLoadsSummary,
  searchFilters: {
    statusFilters: [LoadStatus.All],
    sortBy: 'Date',
    sortAscending: false,
    startDate: null,
    endDate: null,
    filterText: '',
    pageSize: 25,
    pageNumber: 1,
    clearSelectedLoads: true,
  } as AdvancedLoadsSearchFilter,
  displayLoadingIndicator: true,
  loadCreated: false,
  newCreatedLoads: [] as AdvancedLoadItem[],
  snackbarConfig: {} as SnackBarConfig,
  finishedInvoiceStatus: [] as FinishedInvoiceStatus[],
  selectedLoads: [] as AdvancedLoadItem[],
  pageSelected: false,
  billingDescriptions: [] as { id: number; name: string }[],
  billingRateTypes: [] as { id: number; name: string }[],
  billingItems: [] as BillingItem[],
  billingUpdates: [] as BillingItem[],
  billingInvalidItems: [] as BillingValidation[],
  billingLoading: false,
  editedLoad: {} as AdvancedLoadDetail,
  displayDrawerLoadingIndicator: false,
  isLoadEdited: false,
  loadContacts: [] as BusinessPartnerContact[],
  loadValidations: {} as LoadValidations,
  allowExitingDrawer: true,
};

export default function advancedLoadsReducer(state = initialState, action: { type: string; payload: any }) {
  let newState = state;

  switch (action.type) {
    case AdvancedLoadsConstants.SET_PAGINATION:
      return { ...newState, pagination: action.payload };
    case AdvancedLoadsConstants.SET_DISPLAYED_LOADS: {
      const displayedLoads = action.payload as AdvancedLoadItem[];
      if (newState.searchFilters.clearSelectedLoads) {
        return { ...newState, displayedLoads, selectedLoads: [], pageSelected: false };
      } else {
        const updatedDisplayedLoads = displayedLoads.map((load) => {
          if (newState.selectedLoads.find((selectedLoad) => selectedLoad.id === load.id)) {
            return { ...load, selected: true };
          }
          return load;
        });
        const pageSelected = updatedDisplayedLoads.every((load) => load.selected);
        return { ...newState, displayedLoads: updatedDisplayedLoads, pageSelected };
      }
    }
    case AdvancedLoadsConstants.SET_LOAD_SELECTED: {
      const { loadId, selected } = action.payload;
      const displayedLoads = newState.displayedLoads.map((load) => {
        if (load.id === loadId) {
          return { ...load, selected };
        }
        return load;
      });
      const pageSelected = displayedLoads.every((load) => load.selected);
      let selectedLoads = newState.selectedLoads;
      if (selected) {
        selectedLoads = [...selectedLoads, displayedLoads.find((load) => load.id === loadId)];
      } else {
        selectedLoads = selectedLoads.filter((load) => load.id !== loadId);
      }
      return { ...newState, displayedLoads, selectedLoads, pageSelected };
    }
    case AdvancedLoadsConstants.SET_PAGE_LOADS_SELECTED: {
      const selected = action.payload.selected;
      const displayedLoads = newState.displayedLoads.map((load) => {
        if (load.selected !== selected) {
          load = { ...load, selected };
        }
        return load;
      });
      let selectedLoads = newState.selectedLoads;
      if (selected) {
        selectedLoads = [
          ...selectedLoads,
          ...displayedLoads.filter((load) => !selectedLoads.some((selectedLoad) => selectedLoad.id === load.id)),
        ];
      } else {
        selectedLoads = selectedLoads.filter(
          (load) => !displayedLoads.some((displayedLoad) => displayedLoad.id === load.id),
        );
      }
      return {
        ...newState,
        pageSelected: selected,
        selectedLoads,
        displayedLoads,
      };
    }
    case AdvancedLoadsConstants.UNSELECT_ALL_LOADS: {
      const displayedLoads = newState.displayedLoads.map((load) => {
        return { ...load, selected: false };
      });
      return { ...newState, selectedLoads: [], displayedLoads, pageSelected: false };
    }
    case AdvancedLoadsConstants.SET_SUMMARY:
      return { ...newState, summary: action.payload };
    case AdvancedLoadsConstants.SET_STATUS_FILTER:
      return {
        ...newState,
        searchFilters: { ...newState.searchFilters, statusFilters: action.payload, clearSelectedLoads: true },
      };
    case AdvancedLoadsConstants.SET_SORT_FILTER:
      return { ...newState, searchFilters: { ...newState.searchFilters, ...action.payload, clearSelectedLoads: true } };
    case AdvancedLoadsConstants.SET_DATE_FILTER:
      return { ...newState, searchFilters: { ...newState.searchFilters, ...action.payload, clearSelectedLoads: true } };
    case AdvancedLoadsConstants.SET_TEXT_FILTER:
      return {
        ...newState,
        searchFilters: { ...newState.searchFilters, filterText: action.payload, clearSelectedLoads: true },
      };
    case AdvancedLoadsConstants.SET_PAGE_FILTER:
      return {
        ...newState,
        searchFilters: { ...newState.searchFilters, pageNumber: action.payload, clearSelectedLoads: false },
      };
    case AdvancedLoadsConstants.SET_PAGE_SIZE_FILTER:
      return {
        ...newState,
        searchFilters: { ...newState.searchFilters, pageSize: action.payload, clearSelectedLoads: false },
      };
    case AdvancedLoadsConstants.SET_DISPLAY_LOADING_INDICATOR:
      return { ...newState, displayLoadingIndicator: action.payload };
    case AdvancedLoadsConstants.ADD_PLACEHOLDER_LOAD:
      return { ...newState, newCreatedLoads: [...newState.newCreatedLoads, action.payload] };
    case AdvancedLoadsConstants.SET_LOAD_CREATED:
      return { ...newState, loadCreated: action.payload };
    case AdvancedLoadsConstants.RESET_SEARCH_FILTERS:
      return { ...newState, searchFilters: action.payload, clearSelectedLoads: true };
    case AdvancedLoadsConstants.SET_LOAD_MESSAGE_STATUS: {
      return {
        ...newState,
        displayedLoads: newState.displayedLoads.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item,
        ),
      };
    }
    case AdvancedLoadsConstants.ADD_LOAD_FOR_INVOICING:
      return { ...newState, invoiceRequests: [...newState.invoiceRequests, action.payload] };
    case AdvancedLoadsConstants.CLEAR_LOAD_FOR_INVOICING:
      return {
        ...newState,
        invoiceRequests: newState.invoiceRequests.filter((x) => x.invoiceRequestToken !== action.payload),
      };
    case AdvancedLoadsConstants.REFRESH_LOAD_FOR_INVOICING: {
      const { invoiceRequestToken, processing } = action.payload;
      const tempRequests = [...newState.invoiceRequests];
      const foundRequest = tempRequests.find((x) => x.invoiceRequestToken === invoiceRequestToken);
      foundRequest.processing = processing;
      return { ...newState, invoiceRequests: tempRequests };
    }
    case AdvancedLoadsConstants.SET_DOWNLOAD_FLAG: {
      const loadIndex = newState.displayedLoads.findIndex((l) => l.id === action.payload.loadId);
      if (loadIndex !== -1) {
        const updatedLoad = {
          ...newState.displayedLoads[loadIndex],
          canDownloadInvoice: action.payload.flag,
        };
        return {
          ...newState,
          displayedLoads: [
            ...newState.displayedLoads.slice(0, loadIndex),
            updatedLoad,
            ...newState.displayedLoads.slice(loadIndex + 1),
          ],
        };
      }
      return newState;
    }
    case AdvancedLoadsConstants.ADD_FINISHED_INVOICE_STATUS: {
      const { finishedStatus } = action.payload;
      const existingItemIndex = newState.finishedInvoiceStatus.findIndex(
        (item) => item.loadId === finishedStatus.loadId,
      );
      if (existingItemIndex !== -1) {
        const updatedItem = {
          ...newState.finishedInvoiceStatus[existingItemIndex],
          completed: [...newState.finishedInvoiceStatus[existingItemIndex].completed, finishedStatus.completed],
          failed: [...newState.finishedInvoiceStatus[existingItemIndex].failed, finishedStatus.failed],
        };
        const updatedFinishedInvoiceStatus = [
          ...newState.finishedInvoiceStatus.slice(0, existingItemIndex),
          updatedItem,
          ...newState.finishedInvoiceStatus.slice(existingItemIndex + 1),
        ];
        return { ...newState, finishedInvoiceStatus: updatedFinishedInvoiceStatus };
      } else {
        return { ...newState, finishedInvoiceStatus: [...newState.finishedInvoiceStatus, finishedStatus] };
      }
    }
    case AdvancedLoadsConstants.CLEAR_FINISHED_INVOICE_STATUS: {
      return { ...newState, finishedInvoiceStatus: [] };
    }
    case AdvancedLoadsConstants.SET_LOAD_SNACKBAR_CONFIG:
      return { ...newState, snackbarConfig: action.payload };
    case AdvancedLoadsConstants.SET_BILLING_DESCRIPTIONS:
      return { ...newState, billingDescriptions: action.payload };
    case AdvancedLoadsConstants.SET_BILLING_RATE_TYPES:
      return { ...newState, billingRateTypes: action.payload };
    case AdvancedLoadsConstants.SET_BILLING_ITEMS:
      return { ...newState, billingItems: action.payload };
    case AdvancedLoadsConstants.SET_BILLING_UPDATES:
      return { ...newState, billingUpdates: action.payload };
    case AdvancedLoadsConstants.SET_BILLING_INVALID_ITEMS:
      return { ...newState, billingInvalidItems: action.payload };
    case AdvancedLoadsConstants.SET_BILLING_LOADING:
      return { ...newState, billingLoading: action.payload };
    case AdvancedLoadsConstants.SET_EDITED_LOAD:
      return { ...newState, editedLoad: action.payload };
    case AdvancedLoadsConstants.SET_LOAD_DETAILS:
      return { ...newState, loadDetails: action.payload };
    case AdvancedLoadsConstants.SET_DRAWER_LOADING_INDICATOR:
      return { ...newState, displayDrawerLoadingIndicator: action.payload };
    case AdvancedLoadsConstants.SET_IS_LOAD_EDITED:
      return { ...newState, isLoadEdited: action.payload };
    case AdvancedLoadsConstants.SET_LOAD_CONTACTS:
      return { ...newState, loadContacts: action.payload };
    case AdvancedLoadsConstants.SET_LOAD_VALIDATIONS:
      return { ...newState, loadValidations: action.payload };
    case AdvancedLoadsConstants.SET_ALLOW_EXITING_DRAWER:
      return { ...newState, allowExitingDrawer: action.payload };
    default:
      return state;
  }
}
