import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import ReduxThunk from 'redux-thunk';

function configureStore(initialState) {
  let middleware = [ReduxThunk];
  let composeEnhancers = compose;
  if (process.env.NODE_ENV !== 'production') {
    middleware = [...middleware];
    composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;
  }
  return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));
}

const store = configureStore(undefined);
export default store;
