import i18n, { i18n as i18nType } from 'i18next';
import en from './translations/en.json';
import es from './translations/es.json';

const I18N_LANGUAGE = 'i18nextLng';

export const resources = {
  en,
  es,
};

export const addLoadMangagementLanguageResources = (i18nFromShell: i18nType) => {
  i18nFromShell.addResources('en', 'common', en.common);
  i18nFromShell.addResources('es', 'common', es.common);
};

// only use this for unit tests
export const initializeI18nForUnitTest = () => {
  i18n.init({
    resources,
    defaultNS: 'common',
    fallbackLng: 'en',
  });
  return i18n;
};

export const getLanguageFromLocalStorage = () => {
  return localStorage.getItem(I18N_LANGUAGE);
};

export const initializeI18nForFunctions = () => {
  i18n.init({
    resources,
    defaultNS: 'common',
    fallbackLng: 'en',
    lng: getLanguageFromLocalStorage(),
  });
  return i18n;
};

export function translate(key: string) {
  const i18n = initializeI18nForFunctions();
  return i18n.t(key);
}

export type SupportedLanguages = keyof typeof resources;
