import React from 'react';
import { Notification } from '../../interfaces/notification';
import { Button, Dialog } from 'shamrock-clover-ui';
import NotificationService from '../../services/notification.service';
import css from './styles/notification-dialog.scss';
import { Guid } from 'guid-typescript';

interface Props {
  open: boolean;
  onClose: () => void;
  onNavigate?: (path: string) => void;
  notification: Notification;
}
const navigationMap = {
  'fuel entry': '/loads/fuel',
  loads: '/loads',
  'company info': '/loads/company',
  'my fleet': '/loads/myfleet',
  dispatch: '/loads/dispatch',
  'aging report': '/loads/reports/aging',
  ifta: 'loads/reports/ifta',
  statements: '/loads/statements',
  dashboard: '/loads/dashboard',
};
const dialogSizeByTitleMap = {
  'Your WEX fuel transactions are now available': {
    width: 600,
    height: 222,
  },
  'WEX Integration Disabled': {
    width: 670,
    height: 315,
  },
};

const NotificationDialog: React.FC<Props> = ({ open, onClose, onNavigate, notification }: Props) => {
  const notificationService = new NotificationService();
  const content = notification?.message?.content;
  const title = content[0]?.value;
  const filteredContent = content.slice(1).filter(l => l.type !== 'button');
  const buttons = content.filter(l => l.type === 'button');
  const size = dialogSizeByTitleMap[title];

  const markNotificationAsRead = async () => {
    try {
      await notificationService.markNotificationAsRead(notification.notificationId);
    } catch (error) {
      console.error('Error marking notification as read', error);
    }
  };

  const handleOnClose = () => {
    markNotificationAsRead();
    onClose();
  };

  const handleNavigate = (path: string) => {
    onNavigate(navigationMap[path]);
    handleOnClose();
  };

  const renderButtons = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {buttons.map(button => {
          const handleClick = () => {
            if (button?.action?.type === 'close') {
              handleOnClose();
            } else if (button?.action?.type === 'navigate') {
              handleNavigate(button?.action?.target);
            }
          };

          return (
            <Button
              key={Guid.create().toString()}
              onClick={handleClick}
              buttonStyle={button?.action?.type === 'close' ? 'outlined' : 'solid'}
            >
              {button?.value}
            </Button>
          );
        })}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div>
        {filteredContent.map(item => {
          if (item.type === 'text') {
            return <span key={Guid.create().toString()}>{item?.value}</span>;
          } else if (item.type === 'link') {
            return (
              <span
                key={Guid.create().toString()}
                onClick={() => handleNavigate(item?.action?.target)}
                style={{ color: '#0091EA', cursor: 'pointer' }}
                role="none"
              >
                {item?.value}
              </span>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  };

  return (
    <div className={css.container}>
      <Dialog
        open={open}
        actions={renderButtons()}
        content={renderContent()}
        title={title}
        onClose={handleOnClose}
        width={size.width}
        height={size.height}
      />
    </div>
  );
};

export default NotificationDialog;
