import Service from './service';
import { AxiosResponse } from 'axios';

const Config = require('Config');

export default class FeatureService extends Service {
  constructor() {
    super(Config.featureServiceUrl);
  }

  async getFeatures(email): Promise<any> {
    const url = `/active?email=${encodeURIComponent(email)}`;

    const response: AxiosResponse = await this.axios.get(url);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }
}
