import {
  ADD_ENTRY,
  DELETE_ENTRY,
  SET_FUEL_LOG,
  SET_VAlIDATION_ERRORS,
  SORT,
  UPDATE_ENTRY,
  UNDO_DELETE_ENTRY,
  SET_FUEL_LOG_COUNTS,
  REMOVE_DELETED_ENTRY,
  SET_SORT_ORDER,
  SET_SORT_COLUMN,
  SET_FUEL_FILTERS,
  SET_FUEL_ENTRY,
  SET_SIDE_PANEL_ERRORS,
  SET_SIDE_PANEL_LOADER,
  SET_SIDE_PANEL_OPEN,
  SET_SNACKBAR_CONFIG,
  SET_FUEL_TRANSACTIONS_LIST,
  SET_PAGINATION_METADATA,
  SET_LOADING_INDICATOR,
  SET_CONFIRMATION_DIALOG_VISIBILITY,
} from '../../constants/fuelConstants';
import { replaceInArray } from '../../utils/array-utils';
import { Fuel } from '../../interfaces/fuel';
import { sortNumber, sortString } from '../../utils/sorting-utils';
import { Driver } from '../../interfaces/driver';
import { FuelLogFilter } from 'interfaces/fuel-log-filter';
import { FuelTransaction } from '../../interfaces/fuel-transaction';
import { ValidationError } from '../../interfaces/validationError';
import SnackBarConfig from 'interfaces/snack-bar-config';
import { FuelSearchResponseItem } from 'interfaces/fuel-search-response-item';

const initialState = {
  displayedFuelEntries: [],
  fuelMetaData: {},
  fuelLogCounts: {},
  sortColumn: undefined,
  isAscending: false,
  summary: {
    cost: 0,
    quantity: 0,
    totalCost: 0,
  },
  displayLoadingIndicator: false,
  fuelTransactionsList: [] as FuelSearchResponseItem[],
  fuelFilters: {} as FuelLogFilter,
  editedFuel: {} as FuelTransaction,
  sidePanelLoadingIndicator: false,
  sidePanelValidationErrors: [] as ValidationError[],
  openSidePanel: false,
  snackbarConfig: {} as SnackBarConfig,
  displayConfirmationDialog: false,
};

export default function fuelReducer(state = initialState, action) {
  let newState = state;

  const simpleSort = (property: string, sortFunction: Function, reverseUndefined?: boolean) => {
    let newDisplayed = [...newState.displayedFuelEntries];
    newDisplayed = newDisplayed.sort((a: Fuel, b: Fuel) => {
      return sortFunction(a[property], b[property], reverseUndefined);
    });
    return {
      ...newState,
      displayedFuelEntries: action.ascending ? newDisplayed : newDisplayed.reverse(),
    };
  };

  const customSort = sortFunction => {
    let newDisplayed = [...newState.displayedFuelEntries];
    newDisplayed = newDisplayed.sort(sortFunction);
    return {
      ...newState,
      displayedFuelEntries: action.ascending ? newDisplayed : newDisplayed.reverse(),
    };
  };

  switch (action.type) {
    case SET_FUEL_LOG: {
      const { savedEntry, ignoreEdits } = action;
      const tempEntries = newState.displayedFuelEntries.filter(e => e.tempId && e.tempId != savedEntry?.tempId);
      const editedEntries = newState.displayedFuelEntries.filter(e => e.edited && e.id != savedEntry?.id);
      const paginationObject = action.fuelLog;
      let log = paginationObject.results;
      if (editedEntries.length && !ignoreEdits) {
        log = log.map(l => {
          const edited = editedEntries.find(e => e.id == l.id);
          if (edited) {
            return edited;
          }
          return l;
        });
      }
      if (tempEntries.length && !ignoreEdits) {
        log = [...tempEntries, ...log];
      }
      delete paginationObject.results;
      return {
        ...newState,
        displayedFuelEntries: log,
        fuelMetaData: paginationObject,
        summary: paginationObject.summary,
      };
    }
    case ADD_ENTRY: {
      return {
        ...newState,
        displayedFuelEntries: [action.fuelEntry, ...newState.displayedFuelEntries],
      };
    }
    case DELETE_ENTRY: {
      const entry = { ...action.fuelEntry, isDeleted: true };
      const displayedIndex = newState.displayedFuelEntries.map(e => e.tempId || e.id).indexOf(entry.tempId || entry.id);

      if (displayedIndex !== -1) {
        newState = {
          ...newState,
          displayedFuelEntries: replaceInArray(newState.displayedFuelEntries, entry, displayedIndex),
          summary: {
            cost: newState.summary.cost - action.fuelEntry.totalCost,
            quantity: newState.summary.quantity - action.fuelEntry.quantity,
            totalCost: newState.summary.totalCost + action.fuelEntry.totalCost,
          },
        };
      }

      return newState;
    }
    case UPDATE_ENTRY: {
      const entry = { ...action.fuelEntry, edited: true };
      const displayedIndex = newState.displayedFuelEntries.map(e => e.tempId || e.id).indexOf(entry.tempId || entry.id);

      if (displayedIndex !== -1) {
        newState = {
          ...newState,
          displayedFuelEntries: replaceInArray(newState.displayedFuelEntries, entry, displayedIndex),
        };
      }

      return newState;
    }
    case SET_VAlIDATION_ERRORS: {
      const failed = action.failed;

      if (failed.length) {
        newState = {
          ...newState,
          displayedFuelEntries: newState.displayedFuelEntries.map(e => {
            if ((failed[0].id && failed[0].id == e.id) || (failed[0].tempId && failed[0].tempId == e.tempId)) {
              return failed[0];
            }
            return e;
          }),
        };
      }

      return newState;
    }
    case SORT: {
      switch (action.column) {
        case 'date':
        case 'time': {
          return simpleSort('fuelDate', sortNumber, true);
        }
        case 'state':
        case 'postalCode':
        case 'city': {
          return simpleSort(action.column, sortString);
        }
        case 'gallons': {
          return simpleSort('quantity', sortNumber);
        }
        case 'amount': {
          return simpleSort('totalCost', sortNumber);
        }
        case 'truck': {
          const sort = (a: Fuel, b: Fuel) => {
            const aTemp = a?.truck?.truckNumber;
            const bTemp = b?.truck?.truckNumber;
            return sortString(aTemp, bTemp);
          };
          return customSort(sort);
        }
        case 'driver': {
          const sort = (a: Fuel, b: Fuel) => {
            const aTemp = a.driver ? Driver.getFullName(a?.driver) : a.driverName;
            const bTemp = b.driver ? Driver.getFullName(b?.driver) : b.driverName;
            return sortString(aTemp, bTemp);
          };
          return customSort(sort);
        }
        case 'fuelType': {
          const sort = (a: Fuel, b: Fuel) => {
            const aTemp = a.truck ? a?.truck?.fuelType?.name : a.fuelType;
            const bTemp = b.truck ? b?.truck?.fuelType?.name : b.fuelType;
            return sortString(aTemp, bTemp);
          };
          return customSort(sort);
        }
        default: {
          return newState;
        }
      }
    }
    case UNDO_DELETE_ENTRY: {
      const entry = { ...action.fuelEntry, isDeleted: false } as Fuel;
      const displayedIndex = newState.displayedFuelEntries.map(e => e.tempId || e.id).indexOf(entry.tempId || entry.id);

      if (displayedIndex !== -1) {
        newState = {
          ...newState,
          displayedFuelEntries: replaceInArray(newState.displayedFuelEntries, entry, displayedIndex),
          summary: {
            cost: newState.summary.cost + action.fuelEntry.totalCost,
            quantity: newState.summary.quantity + action.fuelEntry.quantity,
            totalCost: newState.summary.totalCost + action.fuelEntry.totalCost,
          },
        };
      }

      return newState;
    }
    case REMOVE_DELETED_ENTRY:
      const { fuelEntry } = action;
      return {
        ...newState,
        displayedFuelEntries: newState.displayedFuelEntries.filter(e => {
          if ((fuelEntry.id && fuelEntry.id != e.id) || (fuelEntry.tempId && fuelEntry.tempId != e.tempId)) {
            return e;
          }
        }),
      };
    case SET_FUEL_LOG_COUNTS:
      return { ...newState, fuelLogCounts: action.fuelLogCounts };
    case SET_SORT_COLUMN:
      const newSortColumn = action.sortColumn;
      return {
        ...newState,
        sortColumn: newSortColumn,
      };
    case SET_SORT_ORDER:
      const newIsAscending = action.isAscending;
      return {
        ...newState,
        isAscending: newIsAscending,
      };
    case SET_FUEL_FILTERS:
      return { ...newState, fuelFilters: action.filters };
    case SET_FUEL_ENTRY:
      return { ...newState, editedFuel: action.fuelEntry };
    case SET_SIDE_PANEL_ERRORS:
      return { ...newState, sidePanelValidationErrors: action.errors };
    case SET_SIDE_PANEL_LOADER:
      return { ...newState, sidePanelLoadingIndicator: action.display };
    case SET_SIDE_PANEL_OPEN:
      return { ...newState, openSidePanel: action.open };
    case SET_SNACKBAR_CONFIG:
      return { ...newState, snackbarConfig: action.config };
    case SET_FUEL_TRANSACTIONS_LIST:
      return { ...newState, fuelTransactionsList: action.fuelTransactions };
    case SET_PAGINATION_METADATA:
      return { ...newState, fuelMetaData: action.metadata, summary: action.metadata.summary };
    case SET_LOADING_INDICATOR:
      return { ...newState, displayLoadingIndicator: action.display };
    case SET_CONFIRMATION_DIALOG_VISIBILITY:
      return { ...newState, displayConfirmationDialog: action.display };
    default:
      return newState;
  }
}
