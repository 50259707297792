import * as Constants from '../../constants/iftaConstants';
import { SORT } from '../../constants/fuelConstants';
import { sortNumber, sortString } from '../../utils/sorting-utils';

const initialState = {
  allReports: [],
  displayedReports: [],
};

export default function iftaReducer(state = initialState, action) {
  let newState = state;

  const simpleSort = (property: string, sortFunction: Function, reverseUndefined?: boolean) => {
    let newAll = [...newState.allReports];
    let newDisplayed = [...newState.displayedReports];
    newAll = newAll.sort((a, b) => {
      return sortFunction(a[property], b[property], reverseUndefined);
    });
    newDisplayed = newDisplayed.sort((a, b) => {
      return sortFunction(a[property], b[property], reverseUndefined);
    });
    return {
      ...newState,
      displayedReports: action.ascending ? newDisplayed : newDisplayed.reverse(),
      allReports: action.ascending ? newAll : newAll.reverse(),
    };
  };

  const updateStatus = (reports: any[], iftaId: number, statusId: number) => {
    let index = reports.findIndex((report: any) => report.id == iftaId);
    if (index >= 0) {
      reports[index].statusId = statusId;
    }
    return reports;
  };

  switch (action.type) {
    case Constants.SET_IFTA_REPORTS: {
      return {
        ...newState,
        allReports: action.reports,
        displayedReports: action.reports,
      };
    }
    case SORT: {
      switch (action.column) {
        case 'miles': {
          return simpleSort('miles', sortNumber);
        }
        case 'gallons': {
          return simpleSort('gallons', sortNumber);
        }
        case 'amount': {
          return simpleSort('amount', sortNumber);
        }
        case 'quarter': {
          return simpleSort('quarter', sortString);
        }
        case 'status': {
          return simpleSort('statusId', sortNumber);
        }
        default: {
          return newState;
        }
      }
    }
    case Constants.UPDATE_IFTA_STATUS: {
      let allReports = updateStatus(
        [...newState.allReports],
        action.iftaStatusUpdate.iftaId,
        action.iftaStatusUpdate.statusId,
      );
      let displayedReports = updateStatus(
        [...newState.displayedReports],
        action.iftaStatusUpdate.iftaId,
        action.iftaStatusUpdate.statusId,
      );

      return {
        ...newState,
        allReports: allReports,
        displayedReports: displayedReports,
      };
    }
    default:
      return newState;
  }
}
