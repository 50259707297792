export const ADD_ENTRY = 'ADD_ENTRY';
export const UPDATE_ENTRY = 'UPDATE_ENTRY';
export const DELETE_ENTRY = 'DELETE_ENTRY';
export const SET_FUEL_LOG = 'SET_FUEL_LOG';
export const SET_VAlIDATION_ERRORS = 'SET_VAlIDATION_ERRORS';
export const SORT = 'SORT';
export const UNDO_DELETE_ENTRY = 'UNDO_DELETE_ENTRY';
export const SET_FUEL_LOG_COUNTS = 'SET_FUEL_LOG_COUNTS';
export const REMOVE_DELETED_ENTRY = 'REMOVE_DELETED_ENTRY';
export const SET_SORT_COLUMN = 'FUEL_SET_SORT_COLUMN';
export const SET_SORT_ORDER = 'FUEL_SET_SORT_ORDER';
export const SET_FUEL_FILTERS = 'SET_FUEL_FILTERS';
export const SET_FUEL_ENTRY = 'SET_FUEL_ENTRY';
export const SET_SIDE_PANEL_ERRORS = 'SET_SIDE_PANEL_ERRORS';
export const SET_SIDE_PANEL_LOADER = 'SET_SIDE_PANEL_LOADER';
export const SET_SIDE_PANEL_OPEN = 'SET_SIDE_PANEL_OPEN';
export const SET_SNACKBAR_CONFIG = 'SET_SNACKBAR_CONFIG';
export const SET_FUEL_TRANSACTIONS_LIST = 'SET_FUEL_TRANSACTIONS_LIST';
export const SET_PAGINATION_METADATA = 'SET_PAGINATION_METADATA';
export const SET_LOADING_INDICATOR = 'SET_LOADING_INDICATOR';
export const SET_CONFIRMATION_DIALOG_VISIBILITY = 'SET_CONFIRMATION_DIALOG_VISIBILITY';
