import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { Fragment } from 'react';
import css from './styles/loading-spinner.scss';

interface Props {
  customStyles?: any;
  size?: number;
}

function LoadingSpinner(props: Props) {
  return (
    <Fragment>
      <div className={css.loadingContainer} style={props.customStyles} />
      <CircularProgress className={css.loadingSpinner} variant={'indeterminate'} size={props.size ? props.size : 60} />
    </Fragment>
  );
}

export default LoadingSpinner;
