import { AgingReportLoadFilter } from 'interfaces/aging-report-load-filter';
import Service from './service';
import { AxiosResponse } from 'axios';
import { ApplyPaymentRequest } from 'interfaces/apply-payment-request';
import { InvoiceEmailRequest } from 'interfaces/invoice-email-request';
import { InvoiceRequest } from 'interfaces/invoice-request';

const Config = require('Config');

export default class AccountingService extends Service {
  constructor() {
    super(Config.accountingServiceUrl);
  }

  // aging report
  async getSummary(): Promise<any> {
    const response: AxiosResponse = await this.axios.get(`loads/aging/summary`);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getAgingItems(filter: AgingReportLoadFilter): Promise<any> {
    const response: AxiosResponse = await this.axios.post('loads/non_factored_aging/search', filter);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async generateExport(
    queryType: unknown,
    asOfDate: any,
    olderThanDays: any,
    filterType: string,
    filterText: string,
  ): Promise<any> {
    const response: AxiosResponse = await this.axios.post(`loads/non_factored_aging/export`, {
      queryType,
      asOfDate,
      olderThanDays,
      filterType,
      filterText,
    });
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async applyPayment(loadPayment: ApplyPaymentRequest[]): Promise<any> {
    const response: AxiosResponse = await this.axios.post('loads/apply_payment', loadPayment);
    if (!response || response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async undoPayment(loadIds: number[]): Promise<any> {
    const response: AxiosResponse = await this.axios.post('loads/undo_apply_payment', loadIds);
    if (!response || response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  // invoice preview and resubmission
  async emailInvoice(loadId: number, loadEmail: InvoiceEmailRequest): Promise<any> {
    const response: AxiosResponse = await this.axios.post(`loads/${loadId}/invoice/email`, loadEmail);
    if (!response || response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async downloadInvoice(loadId: number): Promise<any> {
    const response: AxiosResponse = await this.axios.get(`loads/${loadId}/invoice/download`);
    if (!response || response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async getStatus(invoiceRequestId: number): Promise<any> {
    const response: AxiosResponse = await this.axios.get(`invoices/submit/${invoiceRequestId}/status`);
    if (!response || response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async submit(invoices: InvoiceRequest): Promise<any> {
    const response: AxiosResponse = await this.axios.post('invoices/submit', invoices);
    if (!response || response.status != 200) {
      throw new Error();
    }
    return response.data;
  }
}
