/**
 * Downloads a file from a URL and saves it with a new name.
 *
 * @param downloadUrl - File URL.
 * @param newFileName - The new file name the downloaded file will have.
 **/
export async function downloadFileWithNewName(downloadUrl: string, newFileName: string) {
  try {
    const fetchResponse = await fetch(downloadUrl);
    const blob = await fetchResponse.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = newFileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error downloading the file', error);
  }
}
