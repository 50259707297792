import { ChangedTrialUser, ChangedUser } from 'interfaces/user';
import Service from './service';
import { AxiosResponse } from 'axios';

const Config = require('Config');

export class RtsProUsersService extends Service {
  constructor() {
    super(Config.rtsproUsersServiceUrl);
  }

  public async updateTrialUser(email: string, changed: Partial<ChangedTrialUser>) {
    const url = '/';

    const body = {
      query: `mutation UpdateTrialUser($email: String, $changed: ChangedTrialUser){
        updateTrialUser(email: $email, changed: $changed){
          metaData {
            seenJoyrides {
              ptBasic
            }
          }
        }
      }`,
      variables: {
        email,
        changed,
      },
    };

    const response: AxiosResponse = await this.axios.post(url, body);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  public async updateUser(email: string, changed: Partial<ChangedUser>) {
    const url = '/';
    const userInput = { email };
    const body = {
      query: `mutation UpdateUser($userInput: UserInput, $changed: ChangedUser){
        updateUser(user: $userInput, changed: $changed){
          metaData {
            seenJoyrides {
              ptBasic
            }
          }
        }
      }`,
      variables: {
        userInput,
        changed,
      },
    };

    const response: AxiosResponse = await this.axios.post(url, body);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }
}

export default new RtsProUsersService();
