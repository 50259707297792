import { DispatchDriver } from './dispatch-driver';
import { DispatchTrailer } from './dispatch-trailer';
import { DispatchTrip } from './dispatch-trip';
import { DispatchTruck } from './dispatch-truck';
import { DriverTimeOff } from './driver-time-off';

export class DriverSchedule {
  driver: DispatchDriver;
  truck: DispatchTruck;
  trailer: DispatchTrailer;
  trips: DispatchTrip[];
  timeOffs: DriverTimeOff[];
  numberOfRows: number;
}
