import React, { Component, ReactNode } from 'react';
import { CompanyService, LoadManagementService, UserService } from '../../services';
import { User } from '../../interfaces';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../../redux/actions/appActions';
import Analytics from '../core/analytics';
import FeatureService from '../../services/feature.service';
import FirebaseAnalytics from '../core/firebase-analytics';
import { withRouter } from '../../components/core/with-router';
import MaintenanceAlertDialog from '../../components/alerts/maintenance-alert-dialog';
import { MaintenanceAlert } from '../../interfaces/maintenance-alert';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import { redirectToLogout } from '../../utils/authUtils';
import { FeatureFlags } from '../../enums/featureFlagEnum';
import LogService from '../../services/log.service';
import { Log } from '../../interfaces/log';
import { ApplicationTiers } from '../../enums/application-tier';

const Config = require('Config');

interface LoginProps extends Partial<any> {
  actions: typeof appActions;
  location?: any;
  maintenanceAlert: MaintenanceAlert;
  features: string[];
}

class Login extends Component<LoginProps> {
  bypassMaintenance: boolean;

  constructor(props: LoginProps) {
    super(props);
    this.bypassMaintenance = this.props.features && this.props.features.includes(FeatureFlags.MAINTENANCE_BYPASS);
  }

  async componentDidMount(): Promise<any> {
    let token, email;
    const params = new URLSearchParams(this.props?.location?.search);
    const id = params.get('id');
    const log: Log = { user: '', error: '', additionalDetails: '' };

    try {
      token = LocalAuth.checkSession();
      email = token.email;
      log.user = email;
      this.props.actions.setToken({ token: token.token, email: token.email });
      log.additionalDetails += ' Token set;';
      const userService = new UserService();
      const lmService = new LoadManagementService();
      const result = await Promise.all([
        userService.getUser(email),
        new CompanyService().getCompany(email),
        new FeatureService().getFeatures(email),
        userService.getUserStatus(email),
        lmService.getUserDetails(),
      ]);
      log.additionalDetails += ' Loaded User, Company and Feature service for user;';
      const user: User = { ...result[0], userStatus: result[3] };

      if (result[4]) user.applicationTier = result[4].applicationTier;
      else user.applicationTier = ApplicationTiers.Basic;

      user.companyId = result[1][0]['company_id'];
      const features = result[2];
      if (features && features.length > 0) {
        this.props.actions.setFeatures(features);
        log.additionalDetails += ' Set User Feature Flags';
      }
      const { company } = this.props.actions.setCompany(result[1][0]) as any;
      LocalAuth.setCompany(company);
      LocalAuth.setUser(user as any);
      await this.props.actions.getTMSCompany(company.company_id);
      log.additionalDetails += ' Loaded TMS Company Id';
      this.props.actions.setUser(user);
      FirebaseAnalytics.setUser(user._id, user['userType']);
      Analytics.setDimension('dimension1', user._id);
      Analytics.setDimension('dimension2', user._id);
      Analytics.setDimension('dimension3', user._id);
    } catch (error) {
      log.error = JSON.stringify(error);
      if (error?.response?.status === 500 || (!error.status && error.message === 'Network Error')) {
        await this.props.actions.getMaintenanceAlert();
      }
      new LogService().log(log);
      if (!this.props.maintenanceAlert.visible) {
        if (error === 403 || error?.status == 403 || error == 500 || error?.status == 500) {
          redirectToLogout(Config.loadManagementWebUrl);
        } else {
          if (id) {
            redirectToLogout(`${Config.loadManagementWebUrl}?id=${id}`);
          } else {
            redirectToLogout(Config.loadManagementWebUrl);
          }
        }
      }
    }
  }

  render(): ReactNode {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {this.props.maintenanceAlert.visible && !this.bypassMaintenance && (
          <MaintenanceAlertDialog maintenanceAlert={this.props.maintenanceAlert} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    maintenanceAlert: state.app.maintenanceAlert,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(appActions, dispatch) };
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login as any) as any);
