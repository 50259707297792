const basicPlanFeatures: string[] = [
  'convenient_mobile_and_web_access',
  'basic_load_entry',
  'IFTA_web_only',
  'my_fleet_with_basic_info',
  'unlimited_users',
  'unlimited_trucks_drivers_and_loads',
  'create_loads_from_credit',
  'manage_booked_loads_from_freight',
  'direct_invoicing_to_rts_financial',
];

const premiumFeatures: string[] = [
  'convenient_mobile_and_web_access',
  'advanced_load_entry',
  'IFTA_web_only',
  'my_fleet_with_expanded_profiles',
  'automatic_wex_fuel_import',
  'driver_pay_statements',
  'email_invoices',
  'unlimited_users',
  'unlimited_trucks_drivers_and_loads',
  'create_loads_from_credit',
  'manage_booked_loads_from_freight',
  'direct_invoicing_to_rts_financial',
];

const enterpriseFeatures: string[] = [
  'all_in_one_trucking_software',
  'multiple_integration_including_ifta_quickbooks_eld_and_more',
  'accounting_dispatch_safety_and_maintenance',
  'dedicated_implementation_support_team',
  'driver_mobile_app',
  'unlimited_trucks_drivers_and_loads',
  'create_loads_from_credit',
  'direct_invoicing_to_rts_financial',
];

export const COMPANY_PLANS = [
  {
    name: 'Basic',
    features: basicPlanFeatures,
  },
  {
    name: 'Premium',
    features: premiumFeatures,
  },
  {
    name: 'Enterprise',
    features: enterpriseFeatures,
  },
];
