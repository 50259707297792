import React from 'react';
import { TFunction } from 'i18next';
import { Step } from 'react-joyride';

export const tmsJoyrideSteps = (t: TFunction) => {
  const steps: Step[] = [
    {
      target: '[data-joyrideid="company"]',
      content: <div>{t('enter_your_company_information')}</div>,
      disableBeacon: true,
      showSkipButton: true,
    },
    {
      target: '[data-joyrideid="myfleet"]',
      content: <div>{t('create_driver_and_truck_profiles')}</div>,
      disableBeacon: true,
      showSkipButton: true,
    },
    {
      target: '[data-joyrideid="loads"]',
      content: <div>{t('create_loads')}</div>,
      disableBeacon: true,
      showSkipButton: true,
    },
    {
      target: '[data-joyrideid="invoice-loads"]',
      content: <div>{t('create_invoices')}</div>,
      disableBeacon: true,
      showSkipButton: true,
    },
    {
      target: '[data-joyrideid="statements"]',
      content: <div>{t('create_driver_pay')}</div>,
      disableBeacon: true,
      showSkipButton: true,
    },
    {
      target: '[data-joyrideid="dashboard"]',
      content: <div>{t('view_a_high_level_overview')}</div>,
      disableBeacon: true,
      showSkipButton: true,
      showProgress: false,
    },
  ];
  return steps;
};
