import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Log } from '../interfaces/log';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';

const Config = require('Config');

export default class LogService {
  axios: AxiosInstance;

  constructor() {
    let token = LocalAuth.getTokenValue();
    this.axios = Axios.create({
      baseURL: Config.loadManagementServiceUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async log(log: Log): Promise<any> {
    const response: AxiosResponse = await this.axios.post(`client/log`, log);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }
}
