export type UserStatus = {
  isTrial: boolean;
  trialState: null | string;
};

export const getUserStatusQuery = `query User($email: String) {
  user(email: $email) {
    userStatus {
      isTrial
      trialState
      isRegistered
    }
  }
}`;
