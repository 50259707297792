import Service from './service';
import { AxiosResponse } from 'axios';
import { Company } from '../interfaces';

const Config = require('Config');

export default class CompanyService extends Service {
  constructor() {
    super(Config.companyServiceUrl);
  }

  async getCompany(email): Promise<any> {
    const response: AxiosResponse = await this.axios.get(`${email}?basic=true&validate=true`);
    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getCompanies(): Promise<any> {
    /* TODO: Query companies
    const response: AxiosResponse = await this.axios.get(`${email}?basic=true&validate=true`);
    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
    */
    return await Promise.resolve([
      { _id: 1, company_name: 'Company 1' },
      { _id: 2, company_name: 'Company 2' },
    ]);
  }

  async updateCompany(companyId: string, company: Company): Promise<any> {
    const response: AxiosResponse = await this.axios.patch(`${companyId}`, company);
    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }
}
