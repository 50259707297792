import { FuelTransactionSearchFilter } from 'interfaces/fuel-transaction-search-filter';
import Service from './service';
import { AxiosResponse } from 'axios';
import { FuelTransaction } from 'interfaces/fuel-transaction';
import { UpdateFuelTransactionFleet } from '../interfaces/update-fuel-transaction-fleet';
import { Integration } from '../interfaces/integration';
import { IntegrationStatus } from '../interfaces/integration-status';
import { FuelSearchResponseItem } from '../interfaces/fuel-search-response-item';
import { FuelType } from '../interfaces/fuel-type';

const Config = require('Config');
export default class FuelManagementService extends Service {
  private serviceUrl = Config.fuelManagementServiceUrl;

  constructor() {
    super(Config.fuelManagementServiceUrl);
  }

  async addFuelEntry(fuelEntry: FuelTransaction): Promise<FuelTransaction> {
    const request: AxiosResponse = await this.axios.post(`fuellogs`, fuelEntry);
    if (!request || request.status !== 200) {
      throw new Error();
    }
    return request.data;
  }

  async updateFuelEntry(fuelEntry: FuelTransaction): Promise<FuelTransaction> {
    const request: AxiosResponse = await this.axios.put(`fuellogs`, fuelEntry);
    if (!request || request.status !== 200) {
      throw new Error();
    }
    return request.data;
  }

  async getFuelTypes(): Promise<FuelType[]> {
    const request: AxiosResponse = await this.axios.get(`fuellogs/fueltypes`);
    if (!request || request.status !== 200) {
      throw new Error();
    }
    return request.data;
  }

  async updateFuelTruckAndDriver(data: UpdateFuelTransactionFleet): Promise<any> {
    const request: AxiosResponse = await this.axios.patch(`fuellogs/${data.fuelTransactionId}/fleet`, data);
    if (!request || request.status !== 200) {
      throw new Error();
    }
    return request.data;
  }

  async searchFuelTransactions(searchCriteria: FuelTransactionSearchFilter) {
    const request: AxiosResponse = await this.axios.post(`fuellogs/search`, searchCriteria);
    if (!request || request.status !== 200) {
      throw new Error();
    }
    return request.data;
  }

  async getFuelTransactionById(fuelTransactionId: number): Promise<FuelTransaction> {
    const request: AxiosResponse<FuelTransaction> = await this.axios.get(`fuellogs/${fuelTransactionId}`);
    if (!request || request.status !== 200) {
      throw new Error();
    }
    return request.data;
  }

  async getSingleSearchResultById(fuelTransactionId: number): Promise<FuelSearchResponseItem> {
    const request: AxiosResponse<FuelSearchResponseItem> = await this.axios.get(`fuellogs/search/${fuelTransactionId}`);
    if (!request || request.status !== 200) {
      throw new Error();
    }
    return request.data;
  }

  async deleteFuelTransaction(fuelTransactionId: number) {
    const request: AxiosResponse = await this.axios.delete(`fuellogs/${fuelTransactionId}`);
    if (!request || request.status !== 200) {
      throw new Error();
    }
    return request.data;
  }

  async getSearchTransactionTypeCounts() {
    const request: AxiosResponse = await this.axios.get(`fuellogs/search/transactiontypecounts`);
    if (!request || request.status !== 200) {
      throw new Error();
    }

    return request.data;
  }

  async getIntegrationHistory(integrationId: number, records: number): Promise<any> {
    const response: AxiosResponse = await this.axios.get(`integrations/${integrationId}/audit?numOfRecords=` + records);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getIntegrationStatus(integrationType: string): Promise<any> {
    const response: AxiosResponse = await this.axios.get(`integrations/${integrationType}/isactive`);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getCompanyIntegrations(): Promise<Integration[]> {
    const response: AxiosResponse = await this.axios.get(`integrations`);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async updateIntegration(integration: Integration): Promise<Integration> {
    const response: AxiosResponse = await this.axios.patch(`integrations`, integration);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async updateIntegrationStatus(provider: string, integrationStatus: IntegrationStatus): Promise<IntegrationStatus> {
    const response: AxiosResponse = await this.axios.patch(`integrations/${provider}/IsActive`, integrationStatus);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async addIntegration(integration: Integration): Promise<Integration> {
    const response: AxiosResponse = await this.axios.post(`integrations`, integration);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getIntegration(id: number): Promise<Integration> {
    const response: AxiosResponse = await this.axios.get(`integrations/${id}`);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async verifyIntegrationCredentials(id: number) {
    const response: AxiosResponse = await this.axios.get(`integrations/${id}/verify`);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }
}
