export const SET_DISPLAYED_LOADS = 'SET_DISPLAYED_LOADS';
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export const SET_SORT_FILTER = 'SET_SORT_FILTER';
export const SET_DATE_FILTER = 'SET_DATE_FILTER';
export const SET_TEXT_FILTER = 'SET_TEXT_FILTER';
export const SET_PAGE_FILTER = 'SET_PAGE_FILTER';
export const SET_SELECTED_LOADS = 'SET_SELECTED_LOADS';
export const SET_SUMMARY = 'SET_SUMMARY';
export const SET_DISPLAY_LOADING_INDICATOR = 'SET_DISPLAY_LOADING_INDICATOR';
export const SET_LOAD_ITEM_CHECKED = 'SET_LOADS_ITEM_CHECKED';
export const CHECK_ALL_LOAD_ITEMS = 'CHECK_ALL_LOAD_ITEMS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const ADD_PLACEHOLDER_LOAD = 'ADD_PLACEHOLDER_LOAD';
