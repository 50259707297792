import { TFunction } from 'i18next';
import React from 'react';
import { Step } from 'react-joyride';

export const fuelEntryJoyrideSteps = (t: TFunction) => {
  const steps: Step[] = [
    {
      target: '[data-joyrideid="sort-by-filter"]',
      content: <div>{t('welcome_fuel_entry')}</div>,
      disableBeacon: true,
      showSkipButton: true,
    },
    {
      target: '[data-joyrideid="transation-type-column"]',
      content: <div>{t('new_transaction_items')}</div>,
      disableBeacon: true,
      showSkipButton: true,
    },
    {
      target: '[data-joyrideid="total-columns"]',
      content: <div>{t('fuel_total_columns_description')}</div>,
      disableBeacon: true,
      showSkipButton: true,
    },
    {
      target: '[data-joyrideid="create-button"]',
      content: <div>{t('fuel_create_btn_description')}</div>,
      disableBeacon: true,
      showSkipButton: true,
    },
  ];

  return steps;
};
