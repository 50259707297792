import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  isAuthorized: boolean;
  reroutePath: string;
  element: JSX.Element;
}

const ProtectedPage: FC<Props> = ({ isAuthorized, reroutePath, element }: Props) => {
  return <>{isAuthorized ? element : <Navigate to={reroutePath} replace />}</>;
};
export default ProtectedPage;
