import http from '../../../utils/axios-utils';
import { BusinessPartner } from '../interfaces/BusinessPartner';
import { BusinessPartnerSummary } from '../interfaces/BusinessPartnerSummary';
import { BusinessPartnerBillingTerms } from '../interfaces/BusinessPartnerBillingTerms';
import { BusinessPartnerPayRate } from '../interfaces/BusinessPartnerPayRate';
import { BusinessPartnerPayRatesHistory } from '../interfaces/BusinessPartnerPayRatesHistory';

const Config = require('Config');

export class PartnerService {
  serviceUrl: string;

  constructor() {
    this.serviceUrl = `${Config.loadManagementServiceUrl}businesspartners/`;
  }

  async getPartnersSummary() {
    return await http.get<BusinessPartnerSummary[], unknown>(`${this.serviceUrl}summary`)();
  }

  async getPartnerBillingTerms() {
    return await http.get<BusinessPartnerBillingTerms[], unknown>(`${this.serviceUrl}billingterms`)();
  }

  async getPartnerDetails(partnerId: number): Promise<BusinessPartner> {
    const { data } = await http.get<BusinessPartner, unknown>(`${this.serviceUrl}${partnerId}`)();
    return data;
  }

  async savePartner(partner: BusinessPartner): Promise<BusinessPartner> {
    if (!partner.id) {
      const { data } = await http.post<BusinessPartner, unknown>(this.serviceUrl)(partner);
      return data;
    } else {
      const { data } = await http.patch<BusinessPartner, unknown>(this.serviceUrl)(partner);
      return data;
    }
  }

  async removePartner(partnerId: number): Promise<any> {
    const { data } = await http.delete<BusinessPartner, unknown>(`${this.serviceUrl}${partnerId}`)();
    return data;
  }

  async canRemovePartner(partnerId: number): Promise<boolean> {
    const { data } = await http.get<boolean, unknown>(`${this.serviceUrl}${partnerId}`)();
    return data;
  }

  async updatePartnerState(id: number, active: boolean): Promise<void> {
    await http.patch(`${this.serviceUrl}${id}/isactive/${active}`)();
  }

  async getPayRates(partnerId: number): Promise<BusinessPartnerPayRate[]> {
    const { data } = await http.get<BusinessPartnerPayRate[], unknown>(`${this.serviceUrl}${partnerId}/pay_rate`)();
    return data;
  }
  async getPartnerPayRatesHistpry(partnerId: number): Promise<BusinessPartnerPayRatesHistory[]> {
    const { data } = await http.get<BusinessPartnerPayRatesHistory[], unknown>(
      `${this.serviceUrl}${partnerId}/pay_rate/history`,
    )();
    return data;
  }

  async updatePayRates(partnerId: number, rates: BusinessPartnerPayRate[] | []): Promise<BusinessPartnerPayRate[]> {
    const { data } = await http.patch<BusinessPartnerPayRate[], unknown>(`${this.serviceUrl}${partnerId}/pay_rate`)(
      rates,
    );
    return data;
  }
}
