export interface UpdateUserQueryModel {
  user: {
    email: string;
  };
  changed: {
    metaData: {
      protransport: {
        IftaFaq: boolean;
      };
    };
  };
}

export const updateUserQuery = `mutation UpdateUser($user: UserInput, $changed: ChangedUser) {
    updateUser(user: $user, changed: $changed) {
        email
        metaData {
            protransport {
                IftaFaq
            }
        }
    }
  }`;
