import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shamrock-clover-ui';
import truck from '../../assets/truck_logo.png';
import css from './styles/contact-us.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  returnButtonAction?: Function;
}

const ThankYou = ({ returnButtonAction }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const returnButtonClicked = () => {
    if (!returnButtonAction) navigate(-1);
    else returnButtonAction();
  };

  return (
    <div className={css.contactUsContainer}>
      <div className={css.thankYouTitle}>{` ${t('thank_you')}.`}</div>
      <div className={css.subTitle}>{t('upgrade_request_submitted')}</div>
      <div className={css.returnToProTransportButton}>
        <Button width="260px" height="40px" buttonStyle="outlined" isRounded onClick={returnButtonClicked}>
          {t('return_to')} ProTransport
        </Button>
      </div>
      <img className={css.image} src={truck} />
    </div>
  );
};

export default ThankYou;
