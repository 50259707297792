import Service from './service';
import Axios, { AxiosResponse, CancelToken } from 'axios';

const Config = require('Config');

export default class DebtorService extends Service {
  constructor() {
    super(Config.debtorServiceUrl);
  }

  async searchDebtors(term: string, cancelToken?: CancelToken, clientNumber?: string): Promise<any> {
    const url = 'debtors/search';
    const config = {
      params: {
        name: term,
        clientNumber: clientNumber,
        limit: 5,
      },
      cancelToken: cancelToken,
    };
    try {
      const response: AxiosResponse = await this.axios.get(url, config);
      if (response['status'] !== 200) {
        throw new Error();
      }
      return response['data'];
    } catch (error) {
      if (!Axios.isCancel(error)) {
        throw new Error(error);
      }
    }
    return 'canceled';
  }

  async getDebtors(mcNumbers: string, cancelToken?: CancelToken): Promise<any> {
    const url = 'debtors';
    const totalMcNumbers = mcNumbers.split(',');
    const maxBatchSize = 25;
    const batches = [];

    for (let i = 0; i < totalMcNumbers.length; i += maxBatchSize) {
      const batch = totalMcNumbers.slice(i, i + maxBatchSize);
      batches.push(batch);
    }

    const requests = batches.map(async batch => {
      const config = {
        params: {
          mcNumbers: batch.join(','),
        },
        cancelToken: cancelToken,
      };
      try {
        const response: AxiosResponse = await this.axios.get(url, config);
        if (response['status'] !== 200) {
          throw new Error();
        }
        return response['data'];
      } catch (error) {
        if (!Axios.isCancel(error)) {
          throw new Error(error);
        }
      }
    });

    const results = await Promise.all(requests);
    const combinedData = [].concat(...results);

    return combinedData;
  }
}
