import axios from 'axios';
import { AxiosResponse } from 'axios';
import { AuthorizeSecurePaymentContainerRequest } from '../interfaces/authorize-secure-payment-container-request';
import { AuthorizeSecurePaymentContainerResponse } from '../interfaces/authorize-secure-payment-container-response';
import { AuthorizeCreateTransactionRequest, OpaqueData } from '../interfaces/authorize-create-transaction-request';
import { AuthorizeTransactionResponse } from '../interfaces/authorize-transaction-response';

class AuthorizeNetService {
  loginId: string;
  transactionId: string;
  url: string;

  constructor(loginId: string, transactionId: string, url: string) {
    this.loginId = loginId;
    this.transactionId = transactionId;
    this.url = url;
  }

  async makeOneTimePayment(opaqueData: OpaqueData, amount: string): Promise<AuthorizeTransactionResponse> {
    const request = {
      createTransactionRequest: {
        merchantAuthentication: {
          name: this.loginId,
          transactionKey: this.transactionId,
        },
        transactionRequest: {
          transactionType: 'authCaptureTransaction',
          amount,
          payment: {
            opaqueData,
          },
        },
      },
    };
    return await this.sendCreateTransactionRequest(request);
  }

  async sendSecurePaymentContainerRequest(
    request: AuthorizeSecurePaymentContainerRequest,
  ): Promise<AuthorizeSecurePaymentContainerResponse> {
    let response: AxiosResponse = await axios.post(this.url, request);
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async sendCreateTransactionRequest(
    request: AuthorizeCreateTransactionRequest,
  ): Promise<AuthorizeTransactionResponse> {
    let response: AxiosResponse = await axios.post(this.url, request);
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }
}

export default AuthorizeNetService;
