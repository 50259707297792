import { Notification } from 'interfaces/notification';
import Service from './service';

const Config = require('Config');

export default class NotificationService extends Service {
  constructor() {
    super(Config.notificationServiceUrl);
  }

  async getNotifications(): Promise<Notification[]> {
    const response = await this.axios.get('notification');
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data.results;
  }

  async markNotificationAsRead(notificationId: number): Promise<any> {
    const response = await this.axios.patch(`notification/${notificationId}/Read`);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }
}
