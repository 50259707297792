const Config = require('Config');

export const redirectToLogout = (destination: string) => {
  const redirect = encodeURIComponent(destination);
  window.location.href = `${Config.rtsproUrl}/logout?finaldestination=${redirect}`;
};

export const redirectToLogin = (destination: string) => {
  const origin = encodeURIComponent(destination);
  window.location.href = `${Config.rtsproUrl}/auth?finaldestination=${origin}`;
};
