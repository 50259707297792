import { TFunction } from 'i18next';
import React from 'react';
import { Step } from 'react-joyride';

export const agingJoyrideSteps = (isFinantial: boolean, t: TFunction) => {
  const steps: Step[] = [
    {
      target: '[data-joyrideid="aging"]',
      content: <div>{t('tracks_all_invoiced_loads')}</div>,
      disableBeacon: true,
      showSkipButton: true,
      showProgress: true,
    },
    {
      target: '[data-joyrideid="mark-as-paid"]',
      content: <div>{t('click_mark_as_paid_to_enter')}</div>,
      disableBeacon: true,
      showSkipButton: true,
      showProgress: true,
      placement: 'right',
    },
    {
      target: '[data-joyrideid="invoice-summary"]',
      content: <div>{t('invoice_summary_section')}</div>,
      disableBeacon: true,
      showSkipButton: true,
      showProgress: true,
      placement: 'left',
    },
  ];
  if (isFinantial) {
    steps.push({
      target: '[data-joyrideid="aging-tool-bar"]',
      content: <div>{t('invoices_that_have_been_factored')}</div>,
      disableBeacon: true,
      showSkipButton: true,
      showProgress: true,
    });
  }
  return steps;
};
