import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import css from './styles/sub-header.scss';
import classNames from 'classnames';

interface SubHeaderNavLink extends NavLinkProps {
  isNew?: boolean;
  includeLink?: boolean;
}

const SubHeaderNavLink: React.FC<SubHeaderNavLink> = ({
  isNew = false,
  includeLink = true,
  children,
  ...navLinkProps
}) => {
  if (!includeLink) return null;
  return (
    <div className={classNames(css.navLinkContainer)}>
      <NavLink
        className={({ isActive }) => (isActive ? classNames(css.activeNavlink) : classNames(css.navLink))}
        {...navLinkProps}
      >
        {children}
      </NavLink>
      {isNew && <div className={classNames(css.newIndicator)}>NEW!</div>}
    </div>
  );
};

export default SubHeaderNavLink;
