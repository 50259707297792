import * as LoadConstants from '../../constants/loadConstants';
import { AccountingService, LoadManagementService } from '../../services';
import { setShowWait, showSimpleSnackBar } from './appActions';
import { LoadListItem } from '../../interfaces/load-list-item';
import { InvoiceRequest } from 'interfaces/invoice-request';
import { TFunction } from 'i18next';
import SnackBarConfig from 'interfaces/snack-bar-config';
import { downloadFileWithNewName } from '../../utils/download-utils';
import { InvoiceRequestToken } from 'redux/reducers/loadReducer';

let currentSortColumn;
let currentSort = false;
let currentFilter = '';
let currentPage = 1;
let currentPageSize = 25;

export function selectLoad(id: number) {
  return { type: LoadConstants.SELECT_LOAD, selectedLoad: id };
}

export const highlightLoadId = (id: number) => {
  return {
    type: LoadConstants.HIGHLIGHT_NEW_LOAD,
    highlightedLoadId: id,
  };
};
export function loadLoadSuccess(loads: LoadListItem[]) {
  return { type: LoadConstants.LOAD_LOADS_SUCCESS, loads };
}

export function setOpenLoadEdit(value: boolean) {
  return { type: LoadConstants.SET_OPEN_LOAD_EDIT, value };
}

export function setLoadChanged(value: boolean) {
  return { type: LoadConstants.SET_LOAD_CHANGED, value };
}

export function setOpenUnsavedDialog(value: boolean) {
  return { type: LoadConstants.SET_OPEN_UNSAVED_DIALOG, value };
}
export function setUnsavedDialogFinalAction(value: Function) {
  return { type: LoadConstants.SET_UNSAVED_DIALOG_FINAL_ACTION, value };
}

export function removeCheckedLoad(id: number) {
  return { type: LoadConstants.REMOVE_CHECKED_LOAD, checkedLoadId: id };
}

export function loadFilteredLoads(page?: number, search?: any, sortColumn?: string, pageSize?: number) {
  return async (dispatch, getState) => {
    if (page) {
      currentPage = page;
    }
    if (search !== undefined) {
      currentFilter = search;
    }
    if (pageSize) {
      currentPageSize = pageSize;
    }
    if (sortColumn === currentSortColumn && currentSort === false) {
      currentSortColumn = undefined;
    } else if (sortColumn) {
      currentSort = sortColumn !== currentSortColumn;
      currentSortColumn = sortColumn;
    }
    const finalSearch = search !== undefined ? search : currentFilter;
    const requestObject = {
      ...finalSearch,
      page: page || currentPage,
      sort: currentSort !== undefined ? `${currentSortColumn} ${currentSort ? 'asc' : 'desc'}` : undefined,
      pageSize: currentPageSize,
    };
    dispatch(setShowWait(true));
    const loads = await new LoadManagementService().filterLoads(requestObject);
    dispatch(loadLoadSuccess(loads));
    dispatch(setShowWait(false));
  };
}

export function deleteLoad(id: number) {
  return async (dispatch, getState) => {
    dispatch(setShowWait(true));
    try {
      await new LoadManagementService().deleteLoad(id);
      dispatch(removeCheckedLoad(id));
      dispatch(loadFilteredLoads());
      dispatch(selectLoad(undefined));
    } catch (error) {
      dispatch(showSimpleSnackBar('Error Deleting Load.', 'error'));
      throw error;
    } finally {
      dispatch(setShowWait(false));
    }
  };
}

export function getLoadListItem(loadId: number) {
  return async (dispatch, getState) => {
    dispatch(setShowWait(true));
    try {
      const loadListItem = await new LoadManagementService().getLoadListItemById(loadId);
      dispatch(insertLoadListItem(loadListItem));
    } catch (error) {
      dispatch(showSimpleSnackBar('Error Gettings Load.', 'error'));
      throw error;
    } finally {
      dispatch(setShowWait(false));
    }
  };
}

export function insertLoadListItem(load: LoadListItem) {
  return { type: LoadConstants.INSERT_LOAD_LIST_ITEM, load };
}

export function setLoadChecked(value: boolean, load: LoadListItem) {
  const newLoad = { ...load, isChecked: value };
  return { type: LoadConstants.SET_LOAD_CHECKED, newLoad };
}

export function uncheckAllLoads() {
  return { type: LoadConstants.UNCHECK_ALL_LOADS };
}

export function checkAllLoads() {
  return { type: LoadConstants.CHECK_ALL_LOADS };
}

export function setLoadTruck(load: LoadListItem, truckId: number = null) {
  const payload = { load, truckId };
  return { type: LoadConstants.SET_LOAD_TRUCK, payload };
}

export function setLoadDriver(load: LoadListItem, driverId: number = null, driverName: string = null) {
  const payload = { load, driverId, driverName };
  return { type: LoadConstants.SET_LOAD_DRIVER, payload };
}

export function setUpdateLoadSummary(load: LoadListItem, amount: number, existingAmount: number) {
  const payload = { load, amount, existingAmount };
  return { type: LoadConstants.UPDATE_LOAD_SUMMARY, payload };
}

export function setLoadMessageStatus(
  loadId: number,
  type: 'export' | 'submit' | 'email' | 'done',
  progress: number,
  error?: boolean,
  errorCode?: string,
) {
  const payload = { loadId, type, progress, error, errorCode };
  return { type: LoadConstants.SET_LOAD_MESSAGE_STATUS, payload };
}

export function clearLoadMessageStatus(loadId: number, highlight: boolean) {
  const payload = { loadId, highlight };
  return { type: LoadConstants.CLEAR_LOAD_MESSAGE_STATUS, payload };
}

export function setShowInvoicingJoyride(value: boolean) {
  return { type: LoadConstants.SHOW_INVOICING_JOYRIDE, value };
}

function calculateMaximumProgress(status: any): number {
  let maximumProgress = 0;
  if (status.generationStatusId !== 'NotRequested') {
    maximumProgress++;
  }
  if (status.emailStatusId !== 'NotRequested') {
    maximumProgress++;
  }
  if (status.factoringStatusId !== 'NotRequested') {
    maximumProgress++;
  }
  return maximumProgress;
}

async function calculateFinishedActions(status: any, load?: LoadListItem, dispatch?: any): Promise<number> {
  let completedActions = 0;
  if ((status.generationStatusId === 'Completed' || status.generationStatusId === 'Failed') && status?.downloadUrl) {
    completedActions++;
    if (status.generationStatusId === 'Completed' && load?.canDownloadInvoice) {
      await downloadFileWithNewName(status.downloadUrl, `${load.loadNumber}.pdf`);
      dispatch(setDownloadFlag(load.id, false));
    }
  }
  if (status.emailStatusId === 'Completed' || status.emailStatusId === 'Failed') {
    completedActions++;
  }
  if (status.factoringStatusId === 'Completed' || status.factoringStatusId === 'Failed') {
    completedActions++;
  }
  return completedActions;
}

function getCompletedActionsStatus(status: any): any {
  const completedActionsStatus = {
    loadId: status.loadId,
    completed: [],
    failed: [],
  };
  if (status.generationStatusId === 'Completed') {
    completedActionsStatus.completed.push('export');
  }
  if (status.emailStatusId === 'Completed') {
    completedActionsStatus.completed.push('email');
  }
  if (status.factoringStatusId === 'Completed') {
    completedActionsStatus.completed.push('submit');
  }
  if (status.generationStatusId === 'Failed') {
    completedActionsStatus.failed.push('export');
  }
  if (status.emailStatusId === 'Failed') {
    completedActionsStatus.failed.push('email');
  }
  if (status.factoringStatusId === 'Failed') {
    completedActionsStatus.failed.push('submit');
  }
  return completedActionsStatus;
}

function calculateProgress(completedActions: number, maximumProgress: number): number {
  return Math.floor((completedActions / maximumProgress) * 100);
}

function determineCurrentProcess(status: any): 'export' | 'email' | 'submit' {
  if (status.generationStatusId === 'Working' || status.generationStatusId === 'NotStarted') {
    return 'export';
  } else if (
    status.emailStatusId === 'Working' ||
    (status.generationStatusId === 'NotRequested' && status.factoringStatusId === 'NotRequested')
  ) {
    return 'email';
  } else if (
    status.factoringStatusId === 'Working' ||
    (status.generationStatusId === 'NotRequested' && status.emailStatusId === 'NotRequested')
  ) {
    return 'submit';
  }
}

function determineError(status: any): boolean {
  return (
    status.generationStatusId === 'Failed' || status.emailStatusId === 'Failed' || status.factoringStatusId === 'Failed'
  );
}

export function setLoadSnackbarConfig(
  open: boolean,
  message?: string,
  type?: string,
  time?: number,
  action?: Function,
  width?: string,
) {
  const config = { open, message, type, time, action, width } as SnackBarConfig;
  return { type: LoadConstants.SET_LOAD_SNACKBAR_CONFIG, config };
}

export function getInvoiceStatus(invoiceRequest: InvoiceRequestToken, t: TFunction) {
  return async (dispatch, getState) => {
    if (invoiceRequest) {
      dispatch(refreshLoadForInvoicing(invoiceRequest.invoiceRequestToken, true));
      const status = await new AccountingService().getStatus(invoiceRequest.invoiceRequestToken);
      const loadId = status.loadId;
      const load = getState().loads.allLoads.find(l => l.id === loadId);
      const maximumProgress = calculateMaximumProgress(status);
      const finishedActions = await calculateFinishedActions(status, load, dispatch);
      const progress = calculateProgress(finishedActions, maximumProgress);
      const currentProcess = determineCurrentProcess(status);
      const error = determineError(status);
      let errorCode;

      if (finishedActions === maximumProgress) {
        const invoiceStatuses = getCompletedActionsStatus(status);
        if (error) {
          let failedProcess;
          if (status.generationStatusId === 'Failed') {
            failedProcess = 'export';
            dispatch(
              setLoadSnackbarConfig(
                true,
                t('an_error_occurred_while_exporting', { loadNumber: load.loadNumber }),
                'error',
                2000,
                undefined,
                '400px',
              ),
            );
          } else if (status.emailStatusId === 'Failed') {
            failedProcess = 'email';
            dispatch(
              setLoadSnackbarConfig(
                true,
                t('an_error_occurred_while_email', { loadNumber: load.loadNumber }),
                'error',
                2000,
                undefined,
                '400px',
              ),
            );
            if (status?.errorCode) {
              errorCode = status.errorCode;
            }
          } else if (status.factoringStatusId === 'Failed') {
            failedProcess = 'submit';
            dispatch(
              setLoadSnackbarConfig(
                true,
                t('an_error_occurred_while_submitting', { loadNumber: load.loadNumber }),
                'error',
                2000,
                undefined,
                '400px',
              ),
            );
          }

          dispatch(setLoadMessageStatus(loadId, failedProcess, progress, error, errorCode));
        } else {
          dispatch(clearLoadMessageStatus(loadId, true));
        }
        dispatch(addFinishedInvoiceStatus(invoiceStatuses));
        dispatch(clearLoadForInvoicing(invoiceRequest.invoiceRequestToken));
      } else {
        setTimeout(() => {
          dispatch(setLoadMessageStatus(loadId, currentProcess, progress, error));
          dispatch(refreshLoadForInvoicing(invoiceRequest.invoiceRequestToken, false));
        }, 1000);
      }
    }
  };
}
export function setDownloadFlag(loadId: number, flag: boolean) {
  const payload = { loadId, flag };
  return { type: LoadConstants.SET_DOWNLOAD_FLAG, payload };
}

export function startInvoiceProcessing(invoiceRequest: InvoiceRequest, t: TFunction) {
  return async (dispatch, getState) => {
    const response = await new AccountingService().submit(invoiceRequest);
    if (Array.isArray(response)) {
      response.forEach(r => {
        if (r.generationStatusId !== 'NotRequested') {
          dispatch(setDownloadFlag(r.loadId, true));
        }
        const invoiceRequestToken: InvoiceRequestToken = {
          loadId: r.loadId,
          invoiceRequestToken: r.requestToken,
          processing: true,
        };
        dispatch(addLoadForInvoicing(r.loadId, r.requestToken, true));
        dispatch(getInvoiceStatus(invoiceRequestToken, t));
      });
    } else {
      console.error('Response is not an array');
    }
    dispatch(uncheckAllLoads());
  };
}

export function addFinishedInvoiceStatus(finishedStatus: any) {
  const payload = { finishedStatus };
  return { type: LoadConstants.ADD_FINISHED_INVOICE_STATUS, payload };
}

export function clearFinishedInvoiceStatus() {
  return { type: LoadConstants.CLEAR_FINISHED_INVOICE_STATUS };
}

export function addLoadForInvoicing(loadId: number, invoiceRequestToken: number, processing: boolean) {
  const payload = { loadId, invoiceRequestToken, processing };
  return { type: LoadConstants.ADD_LOAD_FOR_INVOICING, payload };
}

export function clearLoadForInvoicing(invoiceRequestToken: number) {
  return { type: LoadConstants.CLEAR_LOAD_FOR_INVOICING, invoiceRequestToken };
}

export function refreshLoadForInvoicing(invoiceRequestToken: number, processing: boolean) {
  return { type: LoadConstants.REFRESH_LOAD_FOR_INVOICING, payload: { invoiceRequestToken, processing } };
}
